import type {
  BookingResponseDto,
  BookingUpsertRequestDto,
  SplitResponseDto,
  TripSplitsResponseValueDto,
} from 'dtos';
import type { TripAndBookingCreateRequestDto } from 'dtos/booking.dtos';
import type { Options } from 'ky';
import ky from 'utils/client/ky';

export const createTripAndBooking = async (
  tripAndBooking: TripAndBookingCreateRequestDto,
) =>
  ky
    .post('/api/bookings/quick-add', { json: tripAndBooking })
    .json<BookingResponseDto>();

export const createBooking = async (
  booking: BookingUpsertRequestDto,
  tripId: string,
) =>
  ky
    .post(`/api/trips/${tripId}/bookings`, { json: booking })
    .json<BookingResponseDto>();

export const updateBooking = async (
  booking: BookingUpsertRequestDto,
  id: string,
) =>
  ky.put(`/api/bookings/${id}`, { json: booking }).json<BookingResponseDto>();

export const cancelBooking = async (id: string) =>
  ky.post(`/api/bookings/${id}/cancel`).json();

export const voidBooking = async (id: string) =>
  ky.post(`/api/bookings/${id}/void`).json<BookingResponseDto>();

export const deleteBooking = async (id: string): Promise<void> =>
  ky.delete(`/api/bookings/${id}`).json();

export const getBooking = async (
  bookingId: string,
  options?: Options,
): Promise<BookingResponseDto> =>
  ky.get(`api/bookings/${bookingId}`, options).json<BookingResponseDto>();

export const getBookingDefaultSplits = async ({
  bookingId,
  agencyUserId,
}: { bookingId: string; agencyUserId: string }): Promise<
  SplitResponseDto[]
> => {
  const { splits }: TripSplitsResponseValueDto = await ky
    .get(`/api/bookings/${bookingId}/default-splits`, {
      searchParams: { agencyUserId },
    })
    .json();
  return splits;
};
