import { FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';
import type { ReactNode } from 'react';

export default function FormHelperTextError(props: { children: ReactNode }) {
  const { submitCount } = useFormikContext();

  return submitCount > 0 ? (
    <FormHelperText error>{props.children}</FormHelperText>
  ) : null;
}
