import { ExtendedFormDialog } from '@customFormik';
import {
  Button,
  List,
  ListItemText,
  ListSubheader,
  MenuItem,
  LinearProgress as MuiLinearProgress,
  Stack,
  linearProgressClasses,
  styled,
} from '@mui/material';
import BookingPaymentStatusChip from 'components/bookings/BookingPaymentStatusChip';
import FormHelperTextError from 'components/common/forms/FormHelperTextError';
import ListItemWithMenu from 'components/common/lists/ListItemWithMenu';
import { getCurrencyByCode } from 'data/currencies';
import { PayingEntity } from 'dtos';
import { ErrorMessage } from 'formik';
import useOrgBillPaymentMethods from 'hooks/useOrgBillPaymentMethods';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { formatMoneyStr } from 'utils/client/formatting';
import { type BookingFormValues, FieldNames } from '../../schema';
import PaymentForm from '../PaymentForm';
import paymentSchema, { type BookingExpenseFormValues } from '../paymentSchema';
import DepositAndFinalDialog from './depositAndFinalDialog/DepositAndFinalDialog';

const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: 'primary',
  },
}));

type BookingFormSupplierPaymentsProps = {
  payments: BookingExpenseFormValues[];
  setPayments: Dispatch<SetStateAction<BookingExpenseFormValues[]>>;
  bookingFormValues: BookingFormValues;
  bookingVoidedDate?: Date;
  disabled?: boolean;
  required?: boolean;
};

export default function BookingFormSupplierPayments({
  payments,
  setPayments,
  bookingFormValues,
  bookingVoidedDate,
  disabled = false,
  required = false,
}: BookingFormSupplierPaymentsProps) {
  const { data: orgBillPaymentMethods } = useOrgBillPaymentMethods();

  const [paymentDialogDetails, setPaymentDialogDetails] = useState<{
    mode: 'add' | 'edit';
    payment?: BookingExpenseFormValues;
    editingIndex?: number;
    newPaymentNumber?: number;
  } | null>(null);
  const [showDepositDialog, setShowDepositDialog] = useState<boolean>(false);

  const removePayment = (index: number) => {
    setPayments((prev) => prev.filter((p, prevIndex) => prevIndex !== index));
  };

  const totalPaymentsAmount = payments.reduce(
    (acc, payment) => acc + (payment?.amount || 0),
    0,
  );

  const totalBookingAmount = bookingFormValues?.[FieldNames.TOTAL] || 0;

  const linearProgressValue =
    (totalPaymentsAmount * 100) / totalBookingAmount > 100
      ? 100
      : (totalPaymentsAmount * 100) / totalBookingAmount;

  const useCustomAgencyPaymentMethods =
    bookingFormValues?.[FieldNames.PAYING_ENTITY] === PayingEntity.AGENCY &&
    orgBillPaymentMethods?.length > 0;

  const paymentDialogData = {
    onSave: (newOrUpdatedPayment: BookingExpenseFormValues) => {
      if (!newOrUpdatedPayment.paid) newOrUpdatedPayment.paidAt = undefined;

      paymentDialogDetails?.mode === 'edit' &&
      paymentDialogDetails?.editingIndex !== undefined
        ? setPayments((prev) => {
            prev[paymentDialogDetails.editingIndex as number] =
              newOrUpdatedPayment;
            return [...prev];
          })
        : setPayments((prev) => [...prev, newOrUpdatedPayment]);

      return Promise.resolve(newOrUpdatedPayment);
    },
    onSuccess: () => {
      setPaymentDialogDetails(null);
    },
  };

  const formatMoneyWithCurrency = (value: number | undefined) =>
    formatMoneyStr(
      value,
      `${getCurrencyByCode(bookingFormValues[FieldNames.CURRENCY])?.symbol}0`,
      getCurrencyByCode(bookingFormValues[FieldNames.CURRENCY])?.symbol,
    );

  return (
    <>
      <Stack spacing={2}>
        <Stack>
          <List disablePadding>
            <ListSubheader sx={{ mb: 2 }} disableGutters>
              <Stack>
                <ListItemText
                  primary={`Payments to Supplier ${required ? '*' : ''}`}
                  primaryTypographyProps={{ color: 'text.primary' }}
                  secondary={`${formatMoneyWithCurrency(
                    calculateTotalPaymentsAmountInBookingCurrency({
                      payments,
                    }),
                  )} / ${formatMoneyWithCurrency(
                    bookingFormValues?.[FieldNames.TOTAL] || undefined,
                  )}`}
                />
                <LinearProgress
                  variant="determinate"
                  value={payments.length ? linearProgressValue : 0}
                  sx={{ width: '100%' }}
                  color={
                    (totalPaymentsAmount * 100) /
                      (bookingFormValues?.[FieldNames.TOTAL] || 0) >
                    100
                      ? 'error'
                      : undefined
                  }
                />
                <ErrorMessage
                  name={FieldNames.TRACK_PAYMENTS}
                  component={FormHelperTextError}
                />
              </Stack>
            </ListSubheader>

            {payments.map((payment, i) => (
              <ListItemWithMenu
                key={payment?.id || i.toString()}
                listItemProps={{
                  divider: i < payments.length - 1,
                  onClick: () => {
                    setPaymentDialogDetails({
                      mode: 'edit',
                      payment,
                      editingIndex: i,
                    });
                  },
                }}
                menuItems={[
                  <MenuItem key="delete" onClick={() => removePayment(i)}>
                    Delete Payment
                  </MenuItem>,
                ]}
                disabled={disabled}
              >
                <ListItemText
                  primary={formatMoneyStr(
                    payment?.amount || 0,
                    `${getCurrencyByCode(payment.currency)?.symbol}0`,
                    getCurrencyByCode(payment.currency)?.symbol,
                  )}
                  secondary={payment.subject}
                />

                <BookingPaymentStatusChip
                  payment={payment}
                  bookingVoidedDate={bookingVoidedDate}
                />
              </ListItemWithMenu>
            ))}
          </List>
        </Stack>

        <Stack direction={'row'} spacing={2}>
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            disableElevation
            disabled={disabled}
            onClick={() =>
              setPaymentDialogDetails({
                mode: 'add',
                newPaymentNumber: payments.length + 1,
              })
            }
          >
            NEW PAYMENT TO SUPPLIER
          </Button>

          <Button
            variant="contained"
            color="inherit"
            fullWidth
            disableElevation
            onClick={() => setShowDepositDialog(true)}
            disabled={
              disabled ||
              Boolean(!bookingFormValues?.[FieldNames.TOTAL]) ||
              payments.length > 0
            }
          >
            DEPOSIT & FINAL
          </Button>
        </Stack>
      </Stack>
      {paymentDialogDetails && !showDepositDialog && (
        <ExtendedFormDialog
          title={
            paymentDialogDetails.mode === 'add' ? 'New Payment' : 'Edit Payment'
          }
          open={!!paymentDialogDetails}
          setOpen={() => setPaymentDialogDetails(null)}
          FormComponent={PaymentForm}
          onSave={paymentDialogData.onSave}
          onSuccess={paymentDialogData.onSuccess}
          formSchema={paymentSchema}
          context={{
            useCustomAgencyPaymentMethods,
            bookingFormValues,
            ...(paymentDialogDetails.mode === 'add'
              ? {
                  defaultSubject: `Payment ${paymentDialogDetails.newPaymentNumber}`,
                }
              : {}),
          }}
          entity={paymentDialogDetails.payment}
          showSuccessToast={false}
        />
      )}

      {showDepositDialog && !paymentDialogDetails && (
        <DepositAndFinalDialog
          open={showDepositDialog && !paymentDialogDetails}
          setOpen={setShowDepositDialog}
          onSuccess={(values: BookingExpenseFormValues[]) => {
            setPayments((_) => [...values]);
            setShowDepositDialog(false);
          }}
          bookingAmount={bookingFormValues?.[FieldNames.TOTAL] || null}
          bookingAmountHome={
            (bookingFormValues?.[FieldNames.TOTAL] ?? 0) *
              (bookingFormValues?.[FieldNames.EXCHANGE_RATE] ?? 1) || null
          }
          orgBillPaymentMethods={orgBillPaymentMethods}
          useCustomAgencyPaymentMethods={useCustomAgencyPaymentMethods}
          bookingCurrency={bookingFormValues?.[FieldNames.CURRENCY] || null}
          exchangeRate={bookingFormValues?.[FieldNames.EXCHANGE_RATE] || 1}
        />
      )}
    </>
  );
}

const calculateTotalPaymentsAmountInBookingCurrency = ({
  payments,
}: {
  payments: BookingExpenseFormValues[];
}) => {
  const totalPaymentsAmount = payments.reduce(
    (acc, payment) => acc + (payment?.amount || 0),
    0,
  );
  return totalPaymentsAmount;
};
