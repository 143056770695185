import {
  BillPaymentMethodSelector,
  ConversionRateField,
  DatePicker,
  MoneyTextField,
  PaymentMethodSelector,
  Switch,
} from '@customFormik';
import { Payment } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import MoneyProgress from 'components/common/MoneyProgress';
import { getCurrencyByCode } from 'data/currencies';
import { useEffect } from 'react';
import { roundToTwoDecimals } from 'utils/client/formatting';
import { useTypedFormikContext } from 'utils/client/formik';
import getDepositAndFinalDialogHandlers from './depositAndFinalDIalogEventHandlers';
import {
  type ContextValues,
  type DepositAndFinalFormValues,
  FieldNames,
} from './schema';

type DepositAndFinalFormProps = { context: ContextValues };

const DepositAndFinalForm = ({
  context: {
    bookingAmount,
    bookingAmountHome,
    setDisabled,
    orgBillPaymentMethods,
    homeCurrency,
    bookingCurrency,
    useCustomAgencyPaymentMethods,
  },
}: DepositAndFinalFormProps) => {
  const { values, setFieldValue, setValues } =
    useTypedFormikContext<DepositAndFinalFormValues>();

  const sumOfPayments = roundToTwoDecimals(
    (values.depositAmount ?? 0) + (values.finalAmount ?? 0),
  );

  const sumOfHomePayments = roundToTwoDecimals(
    (values.depositHomeAmount ?? 0) + (values.finalHomeAmount ?? 0),
  );

  useEffect(() => {
    setDisabled(sumOfPayments !== bookingAmount);
  }, [sumOfPayments, bookingAmount, setDisabled]);

  const handlers = getDepositAndFinalDialogHandlers({
    values,
    setValues,
    bookingAmount: bookingAmount ?? 0,
  });

  const currency = getCurrencyByCode(bookingCurrency);
  const homeCurrencyData = getCurrencyByCode(homeCurrency);

  const isHomeCurrency = bookingCurrency === homeCurrency;

  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row">
        <Stack spacing={2} width="50%">
          <Typography variant="h6">Deposit</Typography>
          <Stack spacing={2} direction="row">
            <MoneyTextField
              name={FieldNames.DEPOSIT_AMOUNT}
              label="Payment Amount"
              currencyCode={isHomeCurrency ? homeCurrency : bookingCurrency}
              symbol={currency?.symbol}
              fullWidth
              required
              onChange={handlers.handleDepositAmountChange}
            />
            {!isHomeCurrency && (
              <MoneyTextField
                name={FieldNames.DEPOSIT_HOME_AMOUNT}
                label="Home Amount"
                currencyCode={homeCurrency}
                symbol={homeCurrencyData?.symbol}
                fullWidth
                required
                onChange={handlers.handleDepositHomeAmountChange}
              />
            )}
          </Stack>
          {!isHomeCurrency && (
            <ConversionRateField
              name={FieldNames.DEPOSIT_CONVERSION_RATE}
              label="Conversion Rate"
              currencyCode={bookingCurrency}
              required
              isLocked={Boolean(
                values[FieldNames.DEPOSIT_CONVERSION_RATE_LOCKED_AT],
              )}
              onLock={() =>
                setFieldValue(
                  FieldNames.DEPOSIT_CONVERSION_RATE_LOCKED_AT,
                  new Date(),
                )
              }
              onUnlock={() =>
                setFieldValue(
                  FieldNames.DEPOSIT_CONVERSION_RATE_LOCKED_AT,
                  undefined,
                )
              }
              onChange={handlers.handleDepositConversionRateChange}
            />
          )}
          {useCustomAgencyPaymentMethods ? (
            <BillPaymentMethodSelector
              name={FieldNames.DEPOSIT_BILL_PAYMENT_METHOD_ID}
              orgBillPaymentMethods={orgBillPaymentMethods}
              required
              label="Payment Method"
            />
          ) : (
            <PaymentMethodSelector
              name={FieldNames.DEPOSIT_PAYMENT_METHOD}
              label={'Payment Method'}
              required
            />
          )}
          <DatePicker
            name={FieldNames.DEPOSIT_DUE}
            label="Date Due"
            fullWidth
            required
          />
          <Switch name={FieldNames.DEPOSIT_PAID} label="Paid" />
          {values.depositPaid && (
            <DatePicker
              name={FieldNames.DEPOSIT_PAID_AT}
              label="Date Paid"
              fullWidth
              required
            />
          )}
        </Stack>
        <Stack spacing={2} width="50%">
          <Typography variant="h6">Final</Typography>
          <Stack spacing={2} direction="row">
            <MoneyTextField
              name={FieldNames.FINAL_AMOUNT}
              label="Payment Amount"
              currencyCode={isHomeCurrency ? homeCurrency : bookingCurrency}
              symbol={currency?.symbol}
              fullWidth
              required
              onChange={handlers.handleFinalAmountChange}
            />
            {!isHomeCurrency && (
              <MoneyTextField
                name={FieldNames.FINAL_HOME_AMOUNT}
                label="Home Amount"
                symbol={homeCurrencyData?.symbol}
                fullWidth
                required
                onChange={handlers.handleFinalHomeAmountChange}
              />
            )}
          </Stack>
          {!isHomeCurrency && (
            <ConversionRateField
              name={FieldNames.FINAL_CONVERSION_RATE}
              label="Conversion Rate"
              currencyCode={bookingCurrency}
              required
              isLocked={Boolean(
                values[FieldNames.FINAL_CONVERSION_RATE_LOCKED_AT],
              )}
              onLock={() =>
                setFieldValue(
                  FieldNames.FINAL_CONVERSION_RATE_LOCKED_AT,
                  new Date(),
                )
              }
              onUnlock={() =>
                setFieldValue(
                  FieldNames.FINAL_CONVERSION_RATE_LOCKED_AT,
                  undefined,
                )
              }
              onChange={handlers.handleFinalConversionRateChange}
            />
          )}
          {useCustomAgencyPaymentMethods ? (
            <BillPaymentMethodSelector
              name={FieldNames.FINAL_BILL_PAYMENT_METHOD_ID}
              orgBillPaymentMethods={orgBillPaymentMethods}
              required
              label="Payment Method"
            />
          ) : (
            <PaymentMethodSelector
              name={FieldNames.FINAL_PAYMENT_METHOD}
              label={'Payment Method'}
              required
            />
          )}
          <DatePicker
            name={FieldNames.FINAL_DUE}
            label="Date Due"
            fullWidth
            required
          />
          <Switch name={FieldNames.FINAL_PAID} label="Paid" />
          {values.finalPaid && (
            <DatePicker
              name={FieldNames.FINAL_PAID_AT}
              label="Date Paid"
              fullWidth
              required
            />
          )}
        </Stack>
      </Stack>

      <Stack mb={1}>
        <MoneyProgress
          currency={bookingCurrency as string}
          currentValue={sumOfPayments}
          totalValue={bookingAmount ?? 0}
          {...(!isHomeCurrency && {
            homeValue: sumOfHomePayments,
            totalHomeValue: bookingAmountHome ?? 0,
          })}
        />
      </Stack>
    </Stack>
  );
};

export default DepositAndFinalForm;
