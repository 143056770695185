import {
  Alert,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
  tabClasses,
} from '@mui/material';
import ActionsMenuButton from 'components/common/ActionsMenuButton';
import T from 'components/common/T';
import { BookingTab } from 'components/types';
import type { BookingResponseDto } from 'dtos';
import type { BookingConfirmationEmailParserResponse } from 'dtos/bookingEmailParsing.dtos';
import { useUserInfo } from 'hooks';
import { niceShortDate } from 'utils/client/formatting';
import { fullNiceDate } from 'utils/shared/formatting';
import GotToTripChip from '../GoToTripChip';
import BookingEmailParserButton from './BookingEmailParserButton';
import type { BookingFormValues } from './schema';

type BookingDialogTitleProps = {
  tripId: string;
  mode: 'add' | 'edit';
  isQuickAdd?: boolean;
  booking: BookingResponseDto | undefined;
  tab: BookingTab;
  setTab: (tab: BookingTab) => void;
  onParseEmail: (details: BookingConfirmationEmailParserResponse) => void;
  isValid: boolean;
  hasSplitsTabError: boolean;
  showCommissionsTabError: boolean;
  values: BookingFormValues;
  onActionClick: ({ id, action }: { id: string; action: 'void' }) => void;
};

export default function BookingDialogTitle({
  tripId,
  mode,
  isQuickAdd,
  booking,
  tab,
  setTab,
  onParseEmail,
  isValid,
  hasSplitsTabError,
  showCommissionsTabError,
  values,
  onActionClick,
}: BookingDialogTitleProps) {
  const { userInfo } = useUserInfo();

  if (!userInfo) return null;

  const isOrgAdmin = userInfo.isOrgUser && !userInfo.isOrgAssistant;

  return (
    <DialogTitle p={'0!important'} borderBottom={1} borderColor={'divider'}>
      {booking?.voidedAt && (
        <Alert
          severity="error"
          sx={{
            backgroundColor: (theme) => theme.palette.black.dark,
            color: (theme) => theme.palette.common.white,
            '& .MuiAlert-icon': {
              color: (theme) => theme.palette.common.white,
            },
          }}
        >
          Voided {fullNiceDate(booking.voidedAt)}
        </Alert>
      )}
      <Stack direction="row" alignItems="center" px={3} pt={2} mb={1}>
        <Stack>
          <T variant="h6">
            {mode === 'add'
              ? isQuickAdd
                ? 'New Trip & Booking'
                : 'New Booking'
              : 'Edit Booking'}
          </T>
          {mode === 'edit' && booking && (
            <T variant="subtitle2" color="text.secondary">
              {`Created ${niceShortDate(booking.createdAt)}`}
            </T>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" ml="auto" spacing={2}>
          {booking?.trip && (
            <GotToTripChip
              tripName={booking?.trip.name}
              tripId={booking?.trip.id}
            />
          )}
          {mode === 'add' && (
            <BookingEmailParserButton onParse={onParseEmail} tripId={tripId} />
          )}
          {mode === 'edit' && booking && (
            <ActionsMenuButton
              actions={[
                {
                  id: 'edit',
                  label: 'Void',
                  disabled: !booking.isVoidable,
                  tooltip: booking.unvoidableReasons,
                  onClick: () =>
                    onActionClick({ id: booking.id, action: 'void' }),
                },
              ]}
            />
          )}
        </Stack>
      </Stack>
      {!isQuickAdd && (
        <Tabs
          value={tab}
          sx={{ px: 3, [`& .${tabClasses.root}`]: { maxWidth: 'unset' } }}
          onChange={(_event, newValue) => {
            setTab(newValue);
          }}
        >
          <Tab
            label="Booking Details"
            value={BookingTab.BOOKING}
            sx={{
              flex: 1,
              color:
                !isValid && tab !== BookingTab.BOOKING
                  ? 'error.main'
                  : undefined,
            }}
          />
          {mode === 'edit' && isOrgAdmin && (
            <Tab
              label="Advisors & Splits"
              value={BookingTab.ADVISORS_AND_SPLITS}
              sx={{
                flex: 1,
                color:
                  hasSplitsTabError && tab !== BookingTab.ADVISORS_AND_SPLITS
                    ? 'error.main'
                    : undefined,
              }}
            />
          )}
          {values.isConfirmed && (
            <Tab
              label="Commissions"
              value={BookingTab.COMMISSION}
              sx={{
                flex: 1,
                color: showCommissionsTabError ? 'error.main' : undefined,
              }}
            />
          )}
          {mode === 'edit' && booking?.pnr && (
            <Tab label="Sabre" value={BookingTab.SABRE} sx={{ flex: 1 }} />
          )}
          {process.env.NEXT_PUBLIC_ATTACHMENTS_ENABLED === '1' &&
            mode === 'edit' && (
              <Tab
                label="Attachments"
                value={BookingTab.ATTACHMENTS}
                sx={{ flex: 1 }}
              />
            )}
          {mode === 'edit' && isOrgAdmin && (
            <Tab
              label="Activity"
              value={BookingTab.ACTIVITY}
              sx={{ flex: 1 }}
            />
          )}
        </Tabs>
      )}
    </DialogTitle>
  );
}
