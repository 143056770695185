import type { ClientListItem } from '@customFormik';
import type { CreatableListItem, ListItem } from 'components/types';
import { USD } from 'data/currencies';
import {
  type AgencyResponseDto,
  type BookingExpenseUpdateRequestDto,
  type BookingResponseDto,
  type BookingUpsertRequestDto,
  PayingEntity,
  SupplierType,
} from 'dtos';
import type { FormikValues } from 'formik';
import moment from 'moment-timezone';
import { getCommissionPercentage } from 'utils/client/math';
import { createYupSchema } from 'utils/client/types';
import * as yup from 'yup';

export enum FieldNames {
  TRIP = 'trip',
  AGENCY = 'agency',
  DESTINATIONS = 'destinations',
  CLIENT = 'client',
  CORPORATE_GROUP = 'corporateGroup',
  ADDITIONAL_CLIENTS = 'additionalClients',
  SUPPLIER = 'supplier',
  SUPPLIER_TYPE = 'supplierType',
  IS_CONFIRMED = 'isConfirmed',
  CONFIRMATION_NUMBER = 'confirmationNumber',
  CHECK_IN = 'checkIn',
  CHECK_OUT = 'checkOut',
  TOTAL = 'total',
  IS_COMMISSIONABLE = 'isCommissionable',
  COMMISSIONABLE_VALUE = 'commissionableValue',
  COMMISSION_PCT = 'commissionPct',
  COMMISSION = 'commission',
  TAXES_AND_FEES = 'taxesAndFees',
  PAYING_ENTITY = 'payingEntity',
  CURRENCY = 'currency',
  EXCHANGE_RATE = 'exchangeRate',
  EXCHANGE_RATE_LOCKED_AT = 'exchangeRateLockedAt',
  USE_MARKUP = 'useMarkup',
  TRACK_PAYMENTS = 'trackPayments',
  MARKUP = 'markup',
  MARKUP_PCT = 'markupPct',
  TOTAL_WITH_MARKUP = 'totalWithMarkup',
  PAYMENT_DUE = 'paymentDue',
  SUBJECT = 'subject',
  NOTES = 'notes',
  INVOICE_REMARKS = 'invoiceRemarks',
  IS_ARC = 'isArc',
  CLIENT_PAID_TAXES = 'clientPaidTaxes',
}

export type BookingExpensesUpsertRequestDto = BookingExpenseUpdateRequestDto & {
  id?: string;
};

export interface BookingFormValues extends FormikValues {
  [FieldNames.TRIP]: ListItem | null;
  [FieldNames.AGENCY]: ListItem | null;
  [FieldNames.DESTINATIONS]: ListItem[];
  [FieldNames.CLIENT]: ClientListItem | null;
  [FieldNames.CORPORATE_GROUP]: ClientListItem | null;
  [FieldNames.ADDITIONAL_CLIENTS]: ListItem[];
  [FieldNames.SUPPLIER]:
    | (ListItem & { type: SupplierType })
    | CreatableListItem
    | null;
  [FieldNames.SUPPLIER_TYPE]: SupplierType | null;
  [FieldNames.IS_CONFIRMED]: boolean | null;
  [FieldNames.CONFIRMATION_NUMBER]: string | null;
  [FieldNames.CHECK_IN]: Date | null;
  [FieldNames.CHECK_OUT]: Date | null;
  [FieldNames.TOTAL]: number | null;
  [FieldNames.IS_COMMISSIONABLE]: boolean | null;
  [FieldNames.COMMISSIONABLE_VALUE]: number | null;
  [FieldNames.COMMISSION_PCT]: number | null;
  [FieldNames.COMMISSION]: number | null;
  [FieldNames.TAXES_AND_FEES]: number | null;
  [FieldNames.PAYING_ENTITY]: PayingEntity | null;
  [FieldNames.CURRENCY]: string | null;
  [FieldNames.EXCHANGE_RATE]: number;
  [FieldNames.EXCHANGE_RATE_LOCKED_AT]: Date | null;
  [FieldNames.USE_MARKUP]: boolean | null;
  [FieldNames.MARKUP]: number | null;
  [FieldNames.MARKUP_PCT]: number | null;
  [FieldNames.TRACK_PAYMENTS]: boolean | null;
  [FieldNames.TOTAL_WITH_MARKUP]: number | null;
  [FieldNames.PAYMENT_DUE]: Date | null;
  [FieldNames.SUBJECT]: string | null;
  [FieldNames.NOTES]: string | null;
  [FieldNames.INVOICE_REMARKS]: string | null;
  [FieldNames.IS_ARC]: boolean;
  [FieldNames.CLIENT_PAID_TAXES]: number | null;
}

const nullableListItem = yup.object().nullable().shape({
  id: yup.string(),
  name: yup.string(),
});

const schema = ({
  isQuickAdd,
  showTripSelector,
}: {
  isQuickAdd?: boolean;
  showTripSelector?: boolean;
}) => {
  const yupSchema: Record<
    `${Exclude<FieldNames, FieldNames.CORPORATE_GROUP>}`,
    yup.AnySchema
  > = {
    trip: nullableListItem
      .when([], {
        is: () => showTripSelector,
        then: (schema) => schema.required(),
      })
      .label('Trip'),
    agency: nullableListItem
      .when([], {
        is: () => isQuickAdd,
        then: (_) => nullableListItem.required().label('Agency'),
      })
      .label('Agency'),
    destinations: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          name: yup.string(),
        }),
      )
      .nullable()
      .when([], {
        is: () => isQuickAdd,
        then: (schema) =>
          schema.required().min(1, 'At least one destination is required'),
      })
      .label('Destinations'),
    client: nullableListItem
      .when(FieldNames.CORPORATE_GROUP, {
        is: null,
        then: (schema) => schema.required(),
      })
      .label('Client'),
    additionalClients: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          name: yup.string(),
        }),
      )
      .nullable()
      .label('Additional Clients'),
    supplier: yup
      .object()
      .nullable()
      .required()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .label('Supplier'),
    supplierType: yup
      .mixed<SupplierType>()
      .oneOf(Object.values(SupplierType))
      .required()
      .label('Supplier Type'),
    isConfirmed: yup.boolean(),
    confirmationNumber: yup
      .string()
      .label('Confirmation Number')
      .when(FieldNames.IS_CONFIRMED, {
        is: true,
        then: (schema) => schema.required().min(3),
        otherwise: (schema) => schema.optional(),
      }),
    checkIn: yup.date().required().label('Check In'),
    checkOut: yup
      .date()
      .required()
      .test(
        'date_order_test',
        'Check out must be after check in',
        (checkOut: Date | null | undefined, context) => {
          const { checkIn } = context.parent as BookingFormValues;
          return Boolean(!checkIn || !checkOut || checkOut >= checkIn);
        },
      )
      .label('Check Out'),
    total: yup.number().required().label('Total'),
    isCommissionable: yup.boolean().nullable(),
    commissionableValue: yup
      .number()
      .label('Commissionable Value')
      .when(FieldNames.IS_COMMISSIONABLE, {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    taxesAndFees: yup
      .number()
      .when(FieldNames.IS_COMMISSIONABLE, {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      })
      .label('Taxes and Fees'),
    commission: yup
      .number()
      .when(FieldNames.IS_COMMISSIONABLE, {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      })
      .label('Est. Commission'),
    commissionPct: yup
      .number()
      .label('Commission %')
      .when(FieldNames.IS_COMMISSIONABLE, {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      })
      .label('Commission %'),
    payingEntity: yup
      .mixed<PayingEntity>()
      .oneOf(Object.values(PayingEntity))
      .required()
      .label('Payment Type'),
    useMarkup: yup.boolean().nullable(),
    trackPayments: yup.boolean().nullable(),
    markup: yup
      .number()
      .label('Markup')
      .when(FieldNames.USE_MARKUP, {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    markupPct: yup
      .number()
      .label('Markup %')
      .when(FieldNames.USE_MARKUP, {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      })
      .typeError('Markup % is required'),
    totalWithMarkup: yup
      .number()
      .label('Total with Markup')
      .when(FieldNames.USE_MARKUP, {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    currency: yup.string().required().nullable().label('Currency'),
    exchangeRate: yup.number().positive().required().label('Exchange Rate'),
    exchangeRateLockedAt: yup.date().nullable().label('Exchange Rate Locked'),
    paymentDue: yup
      .date()
      .nullable()
      .when('isCommissionable', {
        is: true,
        then: (schema) =>
          isQuickAdd
            ? yup.date().nullable()
            : yup.date().required().nullable().label('Commission Due'),
      }),
    subject: yup.string().optional().nullable().label('Subject'),
    notes: yup.string().optional().nullable().label('Advisor Remarks'),
    invoiceRemarks: yup.string().optional().nullable().label('Invoice Remarks'),
    isArc: yup.boolean(),
    clientPaidTaxes: yup.number().nullable().label('Client Paid GST/HST'),
  };

  const schema =
    createYupSchema<Omit<BookingFormValues, FieldNames.CORPORATE_GROUP>>(
      yupSchema,
    );

  return schema;
};

type GetDefaultValuesProps = {
  booking?: BookingResponseDto | Partial<BookingResponseDto>;
  primaryClient?: ClientListItem;
  corporateGroup?: ClientListItem;
  homeCurrencyCode?: string;
  isQuickAdd?: boolean;
  userAgencies?: AgencyResponseDto[];
};

const getDefaultValues = ({
  booking: b,
  primaryClient,
  corporateGroup,
  homeCurrencyCode,
  isQuickAdd,
  userAgencies,
}: GetDefaultValuesProps): BookingFormValues => {
  return {
    trip: b?.trip ? { id: b.trip.id, name: b.trip.name } : null,
    agency: userAgencies?.[0] ?? null,
    destinations: [],
    client: b?.client
      ? { id: b.client.id, name: b.client.name, type: 'client' }
      : primaryClient || null,
    corporateGroup: b?.corporateGroup
      ? { id: b.corporateGroup.id, name: b.corporateGroup.name, type: 'group' }
      : corporateGroup || null,
    additionalClients: b?.additionalClients || [],
    supplier: b?.supplier || null,
    supplierType: b?.supplierType || b?.supplier?.type || null,
    isConfirmed: b?.isConfirmed !== false, // if confirmed is not specifically false then it is true
    confirmationNumber: b?.confirmationNumber || '',
    checkIn: b?.checkIn ? new Date(b.checkIn) : null,
    checkOut: b?.checkOut ? new Date(b.checkOut) : null,
    total: b?.total ?? null,
    isCommissionable: b?.isCommissionable || isQuickAdd || null,
    commissionableValue: b?.commissionableValue ?? null,
    commissionPct: b?.isCommissionable
      ? getCommissionPercentage(
          Number(b.commissionableValue),
          Number(b.estCommission ?? 0),
        )
      : null,
    commission: b?.estCommission ?? null,
    taxesAndFees: b?.taxesAndFees ?? null,
    payingEntity: isQuickAdd ? PayingEntity.CLIENT : b?.payingEntity ?? null,
    currency: b?.currency || homeCurrencyCode || USD,
    exchangeRate: b?.exchangeRate ?? 1,
    exchangeRateLockedAt: b?.exchangeRateLockedAt
      ? new Date(b.exchangeRateLockedAt)
      : null,
    useMarkup: b?.useMarkup || false,
    markup: b?.markup ?? null,
    markupPct: b?.markupPercent ?? null,
    totalWithMarkup: b?.totalWithMarkup ?? null,
    trackPayments: Boolean(
      !b ||
        b?.payingEntity === PayingEntity.AGENCY ||
        b?.expenses?.data?.length,
    ),
    ...(isQuickAdd ? { trackPayments: false } : {}),
    paymentDue: b?.commissionDue ? new Date(b.commissionDue) : null,
    subject: b?.subject || '',
    notes: b?.notes || '',
    invoiceRemarks: b?.invoiceRemarks || '',
    isArc: b?.isArc || false,
    clientPaidTaxes: b?.clientPaidTaxes ?? null,
  };
};

const getBookingFromFormValues = (
  values: BookingFormValues,
): BookingUpsertRequestDto & {
  tripId?: string;
  agencyId?: string;
  destinationIds: string[];
} => ({
  tripId: values.trip?.id || undefined,
  agencyId: values.agency?.id || undefined,
  destinationIds: values.destinations.map((d) => d.id),
  payingEntity: values.payingEntity as PayingEntity,
  clientId: values.client?.id || undefined,
  corporateGroupId: values.corporateGroup?.id || undefined,
  additionalClientIds: values.additionalClients.map((c) => c.id),
  supplierId: values.supplier?.id || '',
  supplierType: values.supplierType as SupplierType,
  isConfirmed: !!values.isConfirmed,
  confirmationNumber: values.confirmationNumber || '',
  checkIn: values.checkIn
    ? moment(values.checkIn).endOf('day').toISOString()
    : '',
  checkOut: values.checkOut
    ? moment(values.checkOut).endOf('day').toISOString()
    : '',
  isCommissionable: !!values.isCommissionable,
  commissionDue: values.isCommissionable
    ? values.paymentDue?.toISOString()
    : undefined,
  commissionableValue: values.isCommissionable
    ? values.commissionableValue || 0
    : undefined,
  estCommission: values.isCommissionable ? values.commission || 0 : undefined,
  taxesAndFees: values.taxesAndFees || 0,
  total: values.total || 0,
  currency: values.currency || USD,
  exchangeRate: values.exchangeRate ? Number(values.exchangeRate) : 1,
  exchangeRateLockedAt: values.exchangeRateLockedAt?.toISOString() || null,
  markupPercent: values.useMarkup ? values.markupPct || 0 : undefined,
  markup: values.useMarkup ? values.markup || 0 : undefined,
  totalWithMarkup: values.useMarkup ? values.totalWithMarkup || 0 : undefined,
  notes: values.notes || undefined,
  invoiceRemarks: values.invoiceRemarks || undefined,
  subject: values.subject || undefined,
  clientPaidTaxes: values.clientPaidTaxes ?? 0,
});

export { getBookingFromFormValues, getDefaultValues, schema };
