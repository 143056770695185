import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  selectClasses,
} from '@mui/material';
import T from 'components/common/T';

export type PaginationProps = {
  count: number;
  page: number;
  onChangePage: (page: number) => void;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  pageSizeLabel?: string;
};

export const Pagination = ({
  count,
  page,
  onChangePage,
  pageSize,
  onChangePageSize,
  pageSizeLabel = 'Items per page: ',
}: PaginationProps) => {
  return (
    <Stack direction={'row'} justifyContent={'flex-end'} alignItems="center">
      <T variant="body2" mr={2}>
        {pageSizeLabel}
      </T>
      <Select
        size="small"
        variant="standard"
        value={pageSize}
        onChange={(e) => onChangePageSize(Number(e.target.value))}
        disableUnderline
        inputProps={{
          sx: {
            pt: 0.75,
            fontSize: '0.875rem',
          },
        }}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={200}>200</MenuItem>
      </Select>
      <T variant="body2" mx={4}>
        {page * pageSize + 1} - {Math.min(page * pageSize + pageSize, count)} of{' '}
        {count}
      </T>
      <IconButton
        size="small"
        onClick={() => onChangePage(page - 1)}
        disabled={page === 0}
      >
        <ChevronLeft />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => onChangePage(page + 1)}
        disabled={page * pageSize + pageSize >= count}
      >
        <ChevronRight />
      </IconButton>
    </Stack>
  );
};
