//A wrapper for the mui TextField component that adds a focus state and properly handles
//shrinking the label when the field is focused or when the value is set dynamically.

import { InputAdornment, type TextFieldProps } from '@mui/material';
import TextField from 'components/common/forms/TextField';

import PercentFormat from './PercentFormat';

const PercentTextField = (
  props: TextFieldProps & { labelWhenShrunk?: string },
) => {
  const { InputProps, ...other } = props;

  return (
    <TextField
      InputProps={{
        ...InputProps,
        inputComponent: PercentFormat as never,
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
      }}
      {...other}
    />
  );
};

export default PercentTextField;
