import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Stack, Tooltip } from '@mui/material';
import T from 'components/common/T';
import UserAvatar from 'components/common/UserAvatar';
import moment from 'moment';
import type { ChangeLogEventDto } from 'services/changeLog/types';
import { EventLabel } from './EventLabel';

type ChangeLogTimelineItemProps = {
  event: ChangeLogEventDto;
};

export const ChangeLogTimelineItem = ({
  event,
}: ChangeLogTimelineItemProps) => {
  return (
    <TimelineItem>
      <TimelineSeparator sx={{ minWidth: 24, mb: 1 }}>
        {event.changedBy ? (
          <UserAvatar
            color={event.changedBy.color}
            firstName={event.changedBy.firstName}
            lastName={event.changedBy.lastName}
            profilePictureUri={event.changedBy.profileImageUrl}
            sx={{
              mb: 1,
              width: 24,
              height: 24,
            }}
          />
        ) : (
          <TimelineDot variant="outlined" sx={{ mx: 0.75, mt: 0.6 }} />
        )}
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: 0 }}>
        <Stack direction="row" gap={1}>
          <T color="text.secondary">
            {event.changedBy && (
              <T component="span" color="text.primary" display="inline">
                {event.changedBy?.firstName} {event.changedBy?.lastName}{' '}
              </T>
            )}
            <EventLabel event={event} />
          </T>
          <T color="text.secondary">&bull;</T>
          <Tooltip
            title={moment
              .utc(event.changedAt)
              .local()
              .format('MMM D, YYYY h:mm:ss A')}
          >
            <T color="text.secondary" flexShrink={0}>
              {moment.utc(event.changedAt).local().startOf('minute').fromNow()}
            </T>
          </Tooltip>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
};
