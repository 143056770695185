import { Chip, type SxProps } from '@mui/material';
import moment from 'moment-timezone';
import type theme from 'theme';
import { niceShortDate } from 'utils/client/formatting';
import type { BookingExpenseFormValues } from './BookingDialog/tabs/paymentSchema';

const BookingPaymentStatusChip = ({
  payment: { paidAt, dueDate: dueDateStr, paidAtCheckout },
  bookingVoidedDate,
  sx,
  size = 'small',
}: {
  payment: Partial<BookingExpenseFormValues>;
  bookingVoidedDate?: Date;
  sx?: SxProps;
  size?: 'small' | 'medium';
}) => {
  const paidDate = paidAt ? new Date(paidAt) : undefined;
  const dueDate = dueDateStr ? new Date(dueDateStr) : undefined;

  const { color, label } = getChipData({
    paidDate: paidDate,
    dueDate: dueDate,
    paidAtCheckout: !!paidAtCheckout,
    bookingVoidedDate: bookingVoidedDate,
  });

  return <Chip size={size} color={color} label={label} sx={sx} />;
};

const getChipData = ({
  paidDate,
  dueDate,
  paidAtCheckout,
  bookingVoidedDate,
}: {
  paidDate?: Date;
  dueDate?: Date;
  bookingVoidedDate?: Date;
  paidAtCheckout: boolean;
}): {
  color: keyof typeof theme.palette.chips;
  label: string;
} => {
  if (bookingVoidedDate)
    return {
      color: 'error',
      label: `Voided ${niceShortDate(bookingVoidedDate)}`,
    };

  if (paidDate)
    return { color: 'success', label: `Paid ${niceShortDate(paidDate)}` };

  if (paidAtCheckout) return { color: 'info', label: 'Due at Checkout' };

  const isLate = dueDate && moment(dueDate).isBefore(moment());
  if (isLate)
    return { color: 'error', label: `Late ${niceShortDate(dueDate)}` };

  const dueDays = moment(dueDate).diff(moment(), 'days');
  if (dueDays > 7)
    return { color: 'info', label: `Due ${niceShortDate(dueDate)}` };

  return { color: 'warning', label: `Due ${niceShortDate(dueDate)}` };
};

export default BookingPaymentStatusChip;
