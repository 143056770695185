import type { BookingResponseDto } from 'dtos';
import { useConfirm } from 'material-ui-confirm';
import { voidBooking } from 'requests/bookings';

type UseBookingDialogActionHandlersProps = {
  bookingId?: string;
  refreshBooking: () => void;
};

const useBookingDialogActionHandlers = ({
  bookingId,
  refreshBooking,
}: UseBookingDialogActionHandlersProps) => {
  const confirm = useConfirm();
  if (!bookingId) return { onActionClick: () => {} };

  const onVoidBookingClick = async (
    onSuccess: (mode: 'add' | 'edit', booking: BookingResponseDto) => void,
  ) => {
    confirm({
      title: 'Void Booking',
      description:
        'This action will cancel the booking and zero out the associated charges. This action cannot be undone.',
      confirmationText: 'Void Booking',
      cancellationButtonProps: {
        variant: 'contained',
        color: 'inherit',
        disableElevation: true,
      },
      confirmationButtonProps: {
        variant: 'text',
        color: 'error',
      },
    }).then(async () => {
      const voidedBooking = await voidBooking(bookingId);
      refreshBooking();
      onSuccess('edit', voidedBooking);
    });
  };

  const onActionClick = async ({
    action,
    onSuccess,
  }: {
    action: 'void';
    onSuccess: (mode: 'add' | 'edit', booking: BookingResponseDto) => void;
  }) => {
    if (action === 'void') {
      await onVoidBookingClick(onSuccess);
    }
  };

  return {
    onActionClick,
  };
};

export default useBookingDialogActionHandlers;
