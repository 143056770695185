//A wrapper for the mui TextField component that adds a focus state and properly handles
//shrinking the label when the field is focused or when the value is set dynamically.

import { TextField as MuiTextField, type TextFieldProps } from '@mui/material';
import { useState } from 'react';

const TextField = (props: TextFieldProps & { labelWhenShrunk?: string }) => {
  const [active, setActive] = useState(false);
  const {
    label,
    labelWhenShrunk,
    onFocus = () => null,
    onBlur = () => null,
    InputProps,
    InputLabelProps,
    ...other
  } = props;

  const shrinkLabel = active || (props.value !== '' && props.value !== null);

  const useShrunkenLabel =
    (InputLabelProps === undefined && (active || !!props.value)) ||
    InputLabelProps?.shrink;

  return (
    <MuiTextField
      label={useShrunkenLabel ? labelWhenShrunk || label : label}
      onFocus={(e) => {
        setActive(true);
        onFocus(e);
      }}
      onBlur={(e) => {
        setActive(false);
        onBlur(e);
      }}
      InputProps={{
        ...InputProps,
        startAdornment: shrinkLabel ? InputProps?.startAdornment : null,
      }}
      InputLabelProps={{
        shrink: shrinkLabel,
        ...InputLabelProps,
      }}
      {...other}
    />
  );
};

export default TextField;
