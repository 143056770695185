import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Chip } from '@mui/material';
import Link from 'next/link';

const GotToTripChip = ({
  tripId,
  tripName,
}: { tripId: string; tripName: string }) => {
  return (
    <Chip
      component={Link}
      href={`/trips/${tripId}`}
      sx={{ cursor: 'pointer' }}
      label={tripName}
      variant="outlined"
      deleteIcon={<ChevronRightIcon />}
      onDelete={() => {}}
    />
  );
};

export default GotToTripChip;
