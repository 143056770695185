import { DatePicker } from '@customFormik';
import {
  Box,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import {
  type BookingFormValues,
  FieldNames,
} from 'components/bookings/BookingDialog/schema';
import DueDateButtons from 'components/bookings/DueDateButtons';
import { useUserInfoContext } from 'context/user-info.context';
import type { BookingResponseDto, CommissionResponseDto } from 'dtos';
import useTripCommissions from 'hooks/useTripCommissions';
import moment from 'moment-timezone';
import type { SortModel } from 'types/paginatedData';
import {
  formatMoney,
  formatMoneyStr,
  niceShortDate,
} from 'utils/client/formatting';
import { useTypedFormikContext } from 'utils/client/formik';

type ActivityItem = {
  id: string;
  amountUsd: number;
  date: string;
  type: 'commission' | 'adjustment';
  referenceNumber: string;
};

export type CommissionsTabProps = {
  booking: BookingResponseDto | undefined;
  disabled?: boolean;
};

export default function CommissionsTab({
  booking,
  disabled = false,
}: CommissionsTabProps) {
  const userInfo = useUserInfoContext();
  const { commissions } = useTripCommissions({
    query: '',
    page: 0,
    pageSize: 1000,
    sortModel: { sort: 'createdAt', sortDirection: 'desc' } as SortModel,
    bookingId: booking?.id,
  });

  const { values: fv, setFieldValue } =
    useTypedFormikContext<BookingFormValues>();

  function activityList(
    commissions: Array<CommissionResponseDto>,
  ): Array<ActivityItem> {
    return [
      ...commissions.map((c) => ({
        id: c.id,
        amountUsd: c.amountHome,
        date: c.receivedDate,
        type: 'commission' as const,
        referenceNumber: c.referenceNumber,
      })),
      ...commissions.flatMap((c) =>
        c.adjustments.map((a) => ({
          id: a.id,
          amountUsd: a.amountUsd,
          date: a.createdAt,
          type: 'adjustment' as const,
          referenceNumber: c.referenceNumber,
        })),
      ),
    ];
  }

  return (
    <>
      <Stack mt={1 / 2}>
        <FormControl fullWidth>
          <DatePicker
            name="paymentDue"
            label="Commission Due"
            required={Boolean(fv[FieldNames.IS_COMMISSIONABLE])}
            disabled={disabled}
          />
        </FormControl>

        <DueDateButtons
          sx={{ mb: 3, mt: 2 }}
          value={fv.paymentDue}
          referenceDate={fv.checkOut || moment().toDate()}
          onChange={(days) => {
            if (fv.checkOut) {
              setFieldValue(
                FieldNames.PAYMENT_DUE,
                moment(fv.checkOut).add(days, 'days').toDate(),
              );
            }
          }}
          disabled={disabled}
        />
      </Stack>
      {commissions?.length === 0 && (
        <Box>
          <Typography variant="h6">No commissions yet</Typography>
          <Typography variant="subtitle1">
            Commissions and adjustments will appear here.
          </Typography>
        </Box>
      )}
      {commissions?.length > 0 && (
        <Box>
          <List>
            <ListItem divider>
              <Stack direction="row" justifyContent="space-between" flex={1}>
                <Typography variant="body2" color="text.secondary">
                  Expected
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Actual
                </Typography>
              </Stack>
            </ListItem>
            <ListItem>
              <List component="div" disablePadding sx={{ width: '100%' }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  flex={1}
                  px={1}
                >
                  <Typography variant="body1" color="text.primary">
                    {formatMoney(
                      booking?.estCommissionHome || 0,
                      `${userInfo?.homeCurrency?.symbol}0`,
                      userInfo?.homeCurrency?.symbol,
                    )}
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    {formatMoney(
                      commissions?.reduce((acc, c) => acc + c.amountHome, 0) ||
                        0,
                      `${userInfo?.homeCurrency?.symbol}0`,
                      userInfo?.homeCurrency?.symbol,
                    )}
                  </Typography>
                </Stack>
              </List>
            </ListItem>
          </List>

          <List>
            <ListItem divider>
              <Stack direction="row" justifyContent="space-between" flex={1}>
                <Typography variant="body2" color="text.secondary">
                  Activity
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Date
                </Typography>
              </Stack>
            </ListItem>
            {activityList(commissions).map((activityItem) => (
              <ListItem key={activityItem.id}>
                <List
                  component="div"
                  disablePadding
                  sx={{ width: '100%' }}
                  dense
                >
                  <ListItem alignItems="center" divider>
                    <ListItemText
                      primary={formatMoneyStr(
                        activityItem.type === 'commission'
                          ? activityItem.amountUsd
                          : Math.abs(activityItem.amountUsd),
                        `${userInfo?.homeCurrency?.symbol}0`,
                        userInfo?.homeCurrency?.symbol,
                      )}
                      secondary={
                        (activityItem.type === 'commission' &&
                          `Payment - ${activityItem.referenceNumber}`) ||
                        `Adjustment ${
                          (activityItem.amountUsd < 0 && 'Fee') || 'Bonus'
                        } - ${activityItem.referenceNumber}`
                      }
                    />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        float: 'right',
                      }}
                    >
                      {niceShortDate(activityItem.date)}
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </>
  );
}
