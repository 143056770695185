import {
  Alert,
  AlertTitle,
  FormControl,
  List,
  ListSubheader,
  type SxProps,
} from '@mui/material';
import type { GroupedSplitsResponseDto, SplitResponseDto } from 'dtos';
import { useEffect, useState } from 'react';
import { roundToTwoDecimals } from 'utils/client/formatting';
import BookingCommissionSplitsHeader from './BookingCommissionSplitsHeader';
import BookingCommissionSplitsLinearProgress from './BookingCommissionSplitsLinearProgress';
import BookingCommissionSplitList from './BookingCommissionSplitsList';

export const calculateTotalAssignedPct = (
  splits: GroupedSplitsResponseDto | null,
) => {
  if (!splits) return 0;

  const allSplits = [...splits.primarySplits, ...splits.secondarySplits.flat()];

  return roundToTwoDecimals(
    allSplits.reduce((acc, split) => acc + split.takePercent, 0),
  );
};

type CommissionSplitsProps = {
  onResetValues: () => void;
  commissionSplits: GroupedSplitsResponseDto | null;
  onSplitUpdate: (newSplit: SplitResponseDto) => void;
  onSplitRemove: (split: SplitResponseDto) => void;
  sx?: SxProps;
};

const BookingCommissionSplits = ({
  onResetValues,
  commissionSplits,
  onSplitUpdate,
  onSplitRemove,
  sx,
}: CommissionSplitsProps) => {
  const [totalAssignedPct, setTotalAssignedPct] = useState(
    calculateTotalAssignedPct(commissionSplits),
  );

  useEffect(() => {
    setTotalAssignedPct(calculateTotalAssignedPct(commissionSplits));
  }, [commissionSplits]);

  const errorMessage =
    totalAssignedPct !== commissionSplits?.maxPercent
      ? totalAssignedPct < (commissionSplits?.maxPercent as number)
        ? 'Total less than expected'
        : 'Total greater than expected'
      : null;

  return (
    <FormControl sx={sx}>
      <List sx={{ p: 0 }}>
        <ListSubheader>
          <BookingCommissionSplitsHeader
            onResetValues={onResetValues}
            sx={{ mb: 1 }}
          />
          {errorMessage && (
            <Alert severity="error" sx={{ alignItems: 'center', mb: 2 }}>
              <AlertTitle sx={{ p: 0, m: 0 }}>{errorMessage}</AlertTitle>
            </Alert>
          )}
          <BookingCommissionSplitsLinearProgress
            totalAssignedPct={totalAssignedPct}
            maxPercent={commissionSplits?.maxPercent ?? 100}
          />
        </ListSubheader>
        <BookingCommissionSplitList
          commissionSplits={commissionSplits}
          onSplitUpdate={onSplitUpdate}
          onSplitRemove={onSplitRemove}
        />
      </List>
    </FormControl>
  );
};

export default BookingCommissionSplits;
