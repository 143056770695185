import { getCurrencyByCode } from 'data/currencies';
import Decimal from 'decimal.js';
import { FieldType, type UpdateEventDto } from 'services/changeLog/types';
import { formatMoneyStr, niceShortDate } from 'utils/client/formatting';

export function formatUpdateEventValue(event: UpdateEventDto): {
  oldValue: string | null;
  newValue: string | null;
} {
  switch (event.field.type) {
    case FieldType.NUMBER:
      return {
        oldValue:
          event.field.oldValue === null
            ? 'null'
            : new Decimal(event.field.oldValue).toString(),
        newValue:
          event.field.newValue === null
            ? 'null'
            : new Decimal(event.field.newValue).toString(),
      };
    case FieldType.PERCENT: {
      return {
        oldValue:
          event.field.oldValue !== null
            ? `${new Decimal(event.field.oldValue).toDecimalPlaces(2)}%`
            : 'null',
        newValue:
          event.field.newValue !== null
            ? `${new Decimal(event.field.newValue).toDecimalPlaces(2)}%`
            : 'null',
      };
    }
    case FieldType.DATE: {
      return {
        oldValue: niceShortDate(event.field.oldValue ?? ''),
        newValue: niceShortDate(event.field.newValue ?? ''),
      };
    }
    case FieldType.MONEY: {
      const beforeCurrency = getCurrencyByCode(event.field.beforeCurrency);
      const afterCurrency = getCurrencyByCode(event.field.afterCurrency);

      return {
        oldValue: formatMoneyStr(
          Number.parseFloat(event.field.oldValue ?? '0'),
          `${beforeCurrency.symbol}0`,
          beforeCurrency.symbol,
        ),
        newValue: formatMoneyStr(
          Number.parseFloat(event.field.newValue ?? '0'),
          `${afterCurrency.symbol}0`,
          afterCurrency.symbol,
        ),
      };
    }
    default:
      return {
        oldValue: event.field.oldValue || 'null',
        newValue: event.field.newValue || 'null',
      };
  }
}
