import {
  Autocomplete,
  type SxProps,
  TextField,
  type Theme,
  debounce,
} from '@mui/material';
import type { ListItem } from 'components/types';
import type { TripListResponseDto } from 'dtos';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { getTrips } from 'requests/trips';

type TripSelectorProps<T extends string = string> = {
  name: T;
  label: string;
  required?: boolean;
  onChange?: (value: ListItem | null) => void;
  sx?: SxProps<Theme> | undefined;
  disabled?: boolean;
  validateOnChange?: boolean;
};

export default function TripSelector<T extends string = string>({
  name,
  label,
  required,
  onChange,
  sx,
  disabled,
  validateOnChange,
}: TripSelectorProps<T>) {
  const [field, meta, helpers] = useField<ListItem | null>(name);
  const { submitCount } = useFormikContext();

  const [options, setOptions] = useState<ListItem[]>([]);

  const queryTrips = async (query?: string) => {
    const newOptions = await getTrips({
      query: query || '',
      pageSize: 100,
    });

    setOptions(
      newOptions.map((opt: TripListResponseDto) => ({
        id: opt.id,
        name: opt.name,
      })),
    );
  };

  const debouncedGetTrips = debounce(queryTrips, 200);

  // biome-ignore lint/correctness/useExhaustiveDependencies: load trips on mount
  useEffect(() => {
    queryTrips();
  }, []);

  const error = validateOnChange
    ? !!meta.error
    : submitCount > 0 && !!meta.error;
  const helperText = validateOnChange
    ? meta.error
    : submitCount > 0 && meta.error;

  return (
    <Autocomplete
      {...field}
      fullWidth
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      sx={sx}
      disabled={disabled}
      options={options}
      onInputChange={async (_event, newInputValue) =>
        await debouncedGetTrips(newInputValue)
      }
      onChange={(_event, trip) => {
        onChange?.(trip as ListItem | null);
        helpers.setValue(trip as ListItem);
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Regular option
        return option.name as string;
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          onFocus={() => {
            helpers.setTouched(true);
          }}
          onBlur={() => {
            helpers.setTouched(false);
          }}
          label={label}
          error={error}
          helperText={helperText}
          required={required}
        />
      )}
    />
  );
}
