import { ExtendedFormDialog } from '@customFormik';
import { useUserInfoContext } from 'context/user-info.context';
import type { AccountingPaymentMethodResponseDto } from 'dtos';
import { useState } from 'react';
import type { BookingExpenseFormValues } from '../../paymentSchema';
import DepositAndFinalForm from './DepositAndFinalForm';
import schema from './schema';

type DepositAndFinalDialogProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onSuccess: (values: BookingExpenseFormValues[]) => void;
  bookingAmount: number | null;
  bookingCurrency: string | null;
  bookingAmountHome: number | null;
  exchangeRate: number;
  orgBillPaymentMethods: AccountingPaymentMethodResponseDto[];
  useCustomAgencyPaymentMethods: boolean;
};

const DepositAndFinalDialog = ({
  open,
  setOpen,
  onSuccess,
  bookingAmount,
  bookingCurrency,
  bookingAmountHome,
  exchangeRate,
  orgBillPaymentMethods,
  useCustomAgencyPaymentMethods,
}: DepositAndFinalDialogProps) => {
  const userInfo = useUserInfoContext();

  const homeCurrency = userInfo?.homeCurrency?.code ?? null;

  const [disabled, setDisabled] = useState(true);
  return (
    <ExtendedFormDialog
      fullWidth
      maxWidth="md"
      title={'Deposit & Final'}
      open={open}
      setOpen={setOpen}
      FormComponent={DepositAndFinalForm}
      onSave={(values) => Promise.resolve(values)}
      onSuccess={onSuccess}
      formSchema={schema}
      disabled={disabled}
      context={{
        bookingAmount,
        bookingAmountHome,
        setDisabled,
        homeCurrency,
        bookingCurrency,
        exchangeRate,
        useCustomAgencyPaymentMethods,
        orgBillPaymentMethods,
      }}
    />
  );
};

export default DepositAndFinalDialog;
