import { USD } from 'data/currencies';
import type { AccountingPaymentMethodResponseDto } from 'dtos';

import type { FormikValues } from 'formik';
import type { Dispatch, SetStateAction } from 'react';

import { type ExtendedFormSchema, createYupSchema } from 'utils/client/types';
import * as yup from 'yup';
import type { BookingExpenseFormValues } from '../../paymentSchema';

export type ContextValues = {
  bookingAmount: number | null;
  bookingAmountHome: number | null;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  homeCurrency: string | null;
  bookingCurrency: string | null;
  exchangeRate: number;
  orgBillPaymentMethods: AccountingPaymentMethodResponseDto[];
  useCustomAgencyPaymentMethods: boolean;
};

export enum FieldNames {
  DEPOSIT_SUBJECT = 'depositSubject',
  DEPOSIT_AMOUNT = 'depositAmount',
  DEPOSIT_DUE = 'depositDue',
  DEPOSIT_PAYMENT_METHOD = 'depositPaymentMethod',
  DEPOSIT_BILL_PAYMENT_METHOD_ID = 'depositBillPaymentMethodId',
  DEPOSIT_HOME_AMOUNT = 'depositHomeAmount',
  DEPOSIT_CONVERSION_RATE = 'depositConversionRate',
  DEPOSIT_CONVERSION_RATE_LOCKED_AT = 'depositConversionRateLockedAt',
  DEPOSIT_PAID = 'depositPaid',
  DEPOSIT_PAID_AT = 'depositPaidAt',
  FINAL_SUBJECT = 'finalSubject',
  FINAL_AMOUNT = 'finalAmount',
  FINAL_DUE = 'finalDue',
  FINAL_PAYMENT_METHOD = 'finalPaymentMethod',
  FINAL_BILL_PAYMENT_METHOD_ID = 'finalBillPaymentMethodId',
  FINAL_HOME_AMOUNT = 'finalHomeAmount',
  FINAL_CONVERSION_RATE = 'finalConversionRate',
  FINAL_CONVERSION_RATE_LOCKED_AT = 'finalConversionRateLockedAt',
  FINAL_PAID = 'finalPaid',
  FINAL_PAID_AT = 'finalPaidAt',
}

export interface DepositAndFinalFormValues extends FormikValues {
  depositSubject: 'Deposit';
  depositAmount: number | null;
  depositDue: Date | null;
  depositPaymentMethod?: string;
  depositBillPaymentMethodId?: string;
  depositHomeAmount: number | null;
  depositConversionRate: number | null;
  depositConversionRateLockedAt?: Date;
  depositPaid: boolean;
  depositPaidAt: Date | null;
  finalSubject: 'Final';
  finalAmount: number | null;
  finalDue: Date | null;
  finalPaymentMethod?: string;
  finalBillPaymentMethodId?: string;
  finalHomeAmount: number | null;
  finalConversionRate: number | null;
  finalConversionRateLockedAt?: Date;
  finalPaid: boolean;
  finalPaidAt: Date | null;
}

const schema = ({ useCustomAgencyPaymentMethods }: ContextValues) => {
  const paymentMethodSchema = !useCustomAgencyPaymentMethods
    ? yup.string().required()
    : yup.string();

  const billPaymentMethodIdSchema = useCustomAgencyPaymentMethods
    ? yup.string().required()
    : yup.string();

  return createYupSchema<DepositAndFinalFormValues>({
    depositSubject: yup.string().optional().label('Deposit Subject'),
    depositAmount: yup
      .number()
      .min(0)
      .required()
      .nullable()
      .typeError('Deposit Amount must be a number')
      .label('Deposit Amount'),
    depositDue: yup.date().required().nullable().label('Deposit Due'),
    depositPaymentMethod: paymentMethodSchema.label('Deposit Payment Method'),
    depositBillPaymentMethodId: billPaymentMethodIdSchema.label(
      'Deposit Payment Method',
    ),
    depositHomeAmount: yup
      .number()
      .min(0)
      .required()
      .nullable()
      .typeError('Home Amount must be a number')
      .label('Home Amount'),
    depositConversionRate: yup
      .number()
      .min(0)
      .required()
      .nullable()
      .typeError('Conversion Rate must be a number')
      .label('Conversion Rate'),
    depositConversionRateLockedAt: yup
      .date()
      .optional()
      .nullable()
      .label('Conversion Rate Locked At'),
    depositPaid: yup.boolean().required().label('Paid'),
    depositPaidAt: yup
      .date()
      .nullable()
      .label('Payment Made')
      .when('depositPaid', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    finalSubject: yup.string().optional().label('Final Subject'),
    finalAmount: yup
      .number()
      .min(0)
      .required()
      .typeError('Final Amount must be a number')
      .label('Final Amount'),
    finalDue: yup.date().required().nullable().label('Final Due'),
    finalPaymentMethod: paymentMethodSchema.label('Final Payment Method'),
    finalBillPaymentMethodId: billPaymentMethodIdSchema.label(
      'Final Payment Method',
    ),
    finalHomeAmount: yup
      .number()
      .min(0)
      .required()
      .nullable()
      .typeError('Home Amount must be a number')
      .label('Home Amount'),
    finalConversionRate: yup
      .number()
      .min(0)
      .required()
      .nullable()
      .typeError('Conversion Rate must be a number')
      .label('Conversion Rate'),
    finalConversionRateLockedAt: yup
      .date()
      .optional()
      .nullable()
      .label('Conversion Rate Locked At'),
    finalPaid: yup.boolean().required().label('Paid'),
    finalPaidAt: yup
      .date()
      .nullable()
      .label('Payment Made')
      .when('finalPaid', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
  });
};

const getDefaultValues = (
  b: BookingExpenseFormValues[] | undefined,
  context: ContextValues,
): DepositAndFinalFormValues => ({
  depositSubject: 'Deposit',
  depositAmount: null,
  depositDue: null,
  depositHomeAmount: null,
  depositConversionRate: context.exchangeRate ?? 1,
  depositPaid: false,
  depositPaidAt: null,
  finalSubject: 'Final',
  finalAmount: null,
  finalDue: null,
  finalHomeAmount: null,
  finalConversionRate: context.exchangeRate ?? 1,
  finalPaid: false,
  finalPaidAt: null,
});

const getEntityFromFormValues = (
  values: DepositAndFinalFormValues,
  context?: ContextValues,
): BookingExpenseFormValues[] => {
  const exchangeRate = context?.exchangeRate ?? 1;

  return [
    {
      subject: values.depositSubject,
      amount: values.depositAmount,
      amountHome: (values.depositAmount ?? 0) * exchangeRate,
      paymentMethod: context?.orgBillPaymentMethods.find(
        (pm) => pm.id === values.depositBillPaymentMethodId,
      )?.type as string,
      billPaymentMethodId: values.depositBillPaymentMethodId,
      dueDate: values.depositDue,
      paidAtCheckout: false,
      paid: values.depositPaid,
      paidAt: values.depositPaid ? (values.depositPaidAt as Date) : undefined,
      notes: undefined,
      locked: false,
      currency: context?.bookingCurrency ?? USD,
      exchangeRate: values.depositConversionRate ?? 1,
      exchangeRateLockedAt: values.depositConversionRateLockedAt,
    },
    {
      subject: values.finalSubject,
      amount: values.finalAmount,
      amountHome: (values.finalAmount ?? 0) * exchangeRate,
      paymentMethod: context?.orgBillPaymentMethods.find(
        (pm) => pm.id === values.depositBillPaymentMethodId,
      )?.type as string,
      billPaymentMethodId: values.finalBillPaymentMethodId,
      dueDate: values.finalDue,
      paidAtCheckout: false,
      paid: values.finalPaid,
      paidAt: values.finalPaid ? (values.finalPaidAt as Date) : undefined,
      notes: undefined,
      locked: false,
      currency: context?.bookingCurrency ?? USD,
      exchangeRate: values.finalConversionRate ?? 1,
      exchangeRateLockedAt: values.finalConversionRateLockedAt,
    },
  ];
};

const dialogSchema: ExtendedFormSchema<
  BookingExpenseFormValues[],
  BookingExpenseFormValues[],
  DepositAndFinalFormValues,
  typeof schema,
  ContextValues
> = {
  schema,
  getDefaultValues,
  getEntityFromFormValues,
};

export default dialogSchema;
