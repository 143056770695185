import { Box, CircularProgress } from '@mui/material';
import useBookingSabreDetails from 'hooks/useBookingSabreDetails';

export type SabreTabProps = {
  bookingId: string;
};

const SabreTab = ({ bookingId }: SabreTabProps) => {
  const { sabreDetails, isLoading } = useBookingSabreDetails({
    bookingId: bookingId,
  });

  return (
    <Box component="pre">
      {isLoading ? (
        <Box display={'flex'} justifyContent={'center'} height={'100%'}>
          <CircularProgress sx={{ m: 'auto' }} />
        </Box>
      ) : (
        sabreDetails
      )}
    </Box>
  );
};

export default SabreTab;
