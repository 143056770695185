import {
  BillPaymentMethodSelector,
  ConversionRateField,
  DatePicker,
  MoneyTextField,
  PaymentMethodSelector,
  Switch,
  TextField,
} from '@customFormik';
import { TextField as MuiTextField, Stack } from '@mui/material';
import { useUserInfoContext } from 'context/user-info.context';
import { getCurrencyByCode } from 'data/currencies';
import { PayingEntity } from 'dtos';
import { Form } from 'formik';
import useOrgBillPaymentMethods from 'hooks/useOrgBillPaymentMethods';
import { useTypedFormikContext } from 'utils/client/formik';
import PaymentAlert from './PaymentAlert';
import getPaymentDialogHandlers from './paymentDialogHandlers';
import {
  type BookingExpenseFormValues,
  type ContextValues,
  FieldNames,
} from './paymentSchema';

type PaymentFormProps = {
  context: ContextValues;
};
export default function PaymentForm({
  context: { useCustomAgencyPaymentMethods, bookingFormValues },
}: PaymentFormProps) {
  const userInfo = useUserInfoContext();

  const { values: fv, setFieldValue } =
    useTypedFormikContext<BookingExpenseFormValues>();

  const { data: orgBillPaymentMethods } = useOrgBillPaymentMethods();

  const showAccountingAlert = Boolean(userInfo && fv.locked);
  const isLocalCurrency = fv.currency === userInfo?.homeCurrency?.code;
  const isDisabled = !!(fv.locked && !userInfo?.isOrgUser);
  const currency = getCurrencyByCode(fv.currency);
  const arePaidFieldsDisabled = Boolean(
    fv.paidAtCheckout &&
      bookingFormValues?.checkOut &&
      bookingFormValues.checkOut < new Date(),
  );

  const handlers = getPaymentDialogHandlers({ bookingFormValues });

  const PaymentMethodSelectorComponent =
    fv.isArc && bookingFormValues.payingEntity === PayingEntity.CLIENT ? (
      <MuiTextField
        label="Payment Method"
        value={`ARC (${fv.paymentMethod})`}
        fullWidth
        disabled
      />
    ) : useCustomAgencyPaymentMethods ? (
      <BillPaymentMethodSelector
        name={FieldNames.BILL_PAYMENT_METHOD_ID}
        orgBillPaymentMethods={orgBillPaymentMethods}
        required
        label="Payment Method"
        disabled={isDisabled}
      />
    ) : (
      <PaymentMethodSelector
        name={FieldNames.PAYMENT_METHOD}
        label={'Payment Method'}
        required
        disabled={isDisabled}
      />
    );

  return (
    <Form autoComplete="off">
      {showAccountingAlert && (
        <PaymentAlert showWarning={!!userInfo?.isOrgUser} />
      )}

      <Stack spacing={2}>
        <TextField
          name={FieldNames.SUBJECT}
          label="Subject"
          fullWidth
          disabled={isDisabled}
        />

        <Stack direction="row" spacing={2}>
          <MoneyTextField
            name={FieldNames.AMOUNT}
            label="Payment Amount"
            required
            fullWidth
            disabled={isDisabled}
            symbol={currency?.symbol}
            currencyCode={fv.currency}
            onChange={
              !isLocalCurrency ? handlers.handleAmountChange : undefined
            }
          />

          {!isLocalCurrency && (
            <MoneyTextField
              name={FieldNames.AMOUNT_HOME}
              label="Local Payment"
              required
              fullWidth
              symbol={userInfo?.homeCurrency?.symbol}
              currencyCode={userInfo?.homeCurrency?.code}
              disabled={isDisabled}
              onChange={
                !isLocalCurrency ? handlers.handleLocalAmountChange : undefined
              }
            />
          )}
        </Stack>
        {!isLocalCurrency && (
          <ConversionRateField
            name={FieldNames.EXCHANGE_RATE}
            label="Conversion Rate"
            currencyCode={fv.currency}
            disabled={isDisabled || fv.paid}
            required
            isLocked={Boolean(fv.exchangeRateLockedAt)}
            onLock={() =>
              setFieldValue(FieldNames.EXCHANGE_RATE_LOCKED_AT, new Date())
            }
            onUnlock={() =>
              setFieldValue(FieldNames.EXCHANGE_RATE_LOCKED_AT, undefined)
            }
            onChange={handlers.handleExchangeRateChange}
          />
        )}

        {PaymentMethodSelectorComponent}

        <DatePicker
          label="Payment Due"
          required
          fullWidth
          name={FieldNames.DUE_DATE}
          disabled={isDisabled || fv.paidAtCheckout}
        />

        <Stack>
          <Switch
            name={FieldNames.PAID_AT_CHECKOUT}
            label="Due at Checkout"
            disabled={isDisabled || Boolean(!bookingFormValues.checkOut)}
            onChange={(_, checked) =>
              handlers.handlePaidAtCheckoutChange(checked)
            }
          />

          <Switch
            name={FieldNames.PAID}
            label="Paid"
            disabled={isDisabled || arePaidFieldsDisabled}
          />
        </Stack>

        {fv.paid && (
          <DatePicker
            label="Payment Made"
            required
            fullWidth
            name={FieldNames.PAID_AT}
            disabled={isDisabled || arePaidFieldsDisabled}
            onChange={handlers.handlePaidAtChange}
          />
        )}

        <TextField
          hiddenLabel
          variant="filled"
          fullWidth
          name={FieldNames.NOTES}
          placeholder="Notes"
          multiline
          rows={1}
        />
      </Stack>
    </Form>
  );
}
