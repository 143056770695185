import AddIcon from '@mui/icons-material/Add';
import { Button, Stack } from '@mui/material';
import BookingCommissionSplits from 'components/bookings/BookingDialog/tabs/splits/BookingCommissionSplits';
import type { GroupedSplitsResponseDto, SplitResponseDto } from 'dtos';
import { queryTypes, useQueryState } from 'next-usequerystate';
import { useEffect, useState } from 'react';
import BookingSplitsDialog from './splits/BookingSplitsDialog';

export type AdvisorsAndSplitsTabProps = {
  bookingId: string;
  commissionSplits?: GroupedSplitsResponseDto;
  onCommissionSplitsChange: (newSplits: GroupedSplitsResponseDto) => void;
};

const emptySplit: GroupedSplitsResponseDto = {
  maxPercent: 100,
  primarySplits: [],
  secondarySplits: [],
};

export default function AdvisorsAndSplitsTab({
  bookingId,
  commissionSplits,
  onCommissionSplitsChange,
}: AdvisorsAndSplitsTabProps) {
  const [splitMode, setSplitMode] = useQueryState(
    'splitMode',
    queryTypes.stringEnum(['add', 'edit']),
  );

  const [splits, setSplits] = useState<GroupedSplitsResponseDto>(
    commissionSplits || emptySplit,
  );

  useEffect(() => {
    onCommissionSplitsChange(splits);
  }, [splits]);

  const onSplitUpdate = (split: SplitResponseDto) => {
    const primarySplits = splits.primarySplits.map((primarySplit) =>
      primarySplit.agencyId === split.agencyId &&
      primarySplit.agencyUserId === split.agencyUserId
        ? { ...primarySplit, takePercent: split.takePercent }
        : primarySplit,
    );
    const secondarySplits = splits.secondarySplits.map((secondarySplits) =>
      secondarySplits.map((secondarySplit) =>
        secondarySplit.agencyId === split.agencyId &&
        secondarySplit.agencyUserId === split.agencyUserId
          ? { ...secondarySplit, takePercent: split.takePercent }
          : secondarySplit,
      ),
    );

    const newSplits = {
      ...splits,
      primarySplits,
      secondarySplits,
    };

    setSplits(newSplits);
  };

  const onSplitAdded = (addedSplits: SplitResponseDto[]) =>
    setSplits({
      ...splits,
      secondarySplits: [...splits.secondarySplits, addedSplits],
    });

  const onResetValues = () => setSplits(commissionSplits || emptySplit);
  const onSplitRemove = (split: SplitResponseDto) => {
    // Assuming we can't remove an advisor from the primary split
    const secondarySplits = splits.secondarySplits.filter(
      (secondarySplits) =>
        !secondarySplits.some(
          (secondarySplit) =>
            secondarySplit.agencyUserId === split.agencyUserId,
        ),
    );

    const newSplits = {
      ...splits,
      secondarySplits,
    };

    setSplits(newSplits);
  };

  const agencyUserIdsWithSplits = Array.from(
    new Set([
      ...(commissionSplits?.primarySplits.map((split) => split.agencyUserId) ??
        []),
      ...(commissionSplits?.secondarySplits.flatMap((secondarySplits) =>
        secondarySplits.map((split) => split.agencyUserId),
      ) ?? []),
    ]),
  );

  return (
    <Stack>
      <BookingCommissionSplits
        onResetValues={onResetValues}
        commissionSplits={splits}
        onSplitUpdate={onSplitUpdate}
        onSplitRemove={onSplitRemove}
      />
      <Button
        variant="text"
        onClick={() => setSplitMode('add')}
        sx={{ mt: 2, mr: 'auto' }}
        startIcon={<AddIcon />}
      >
        New Advisor Split
      </Button>
      {splitMode === 'add' && (
        <BookingSplitsDialog
          bookingId={bookingId}
          open={splitMode === 'add'}
          setOpen={(open) => setSplitMode(open ? 'add' : null)}
          mode={splitMode}
          onSplitAdded={onSplitAdded}
          excludeAgencyUserIds={agencyUserIdsWithSplits}
        />
      )}
    </Stack>
  );
}
