import type { AccountingPaymentMethodResponseDto } from 'dtos';
import useSWR from 'swr';
import { fetcher } from 'utils/client/swr/jsonFetcher';

type UseOrgBillPaymentMethodsResponse = {
  data: AccountingPaymentMethodResponseDto[];
  isLoading: boolean;
  error: Error | undefined;
  refresh: () => void;
};

export default function useOrgBillPaymentMethods(): UseOrgBillPaymentMethodsResponse {
  const endpoint = 'bill-payment-methods';
  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<{ data: AccountingPaymentMethodResponseDto[] }, Error>(
    endpoint,
    fetcher,
  );

  return {
    data: response?.data || [],
    isLoading,
    error,
    refresh: () => void mutate(),
  };
}
