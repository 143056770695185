import type { ClientListItem } from '@customFormik';
import type { CreatableListItem, ListItem } from 'components/types';
import {
  type ClientResponseDto,
  type ErrorResponseDto,
  PayingEntity,
  type SupplierResponseDto,
  type SupplierType,
} from 'dtos';
import type { BookingConfirmationEmailParserResponse } from 'dtos/bookingEmailParsing.dtos';
import moment from 'moment-timezone';
import type { Dispatch, SetStateAction } from 'react';
import { getExchangeRate } from 'requests/exchangeRates';
import { getSupplier } from 'requests/suppliers';
import { getCommission, getCommissionPercentage } from 'utils/client/math';
import type { UseFormikHelpers } from 'utils/client/types';
import { type BookingFormValues, FieldNames as fields } from './schema';
import type { BookingExpenseFormValues } from './tabs/paymentSchema';

type BookingFormEventHandlerProps = {
  values: BookingFormValues;
  setFieldValue: UseFormikHelpers<BookingFormValues>['setFieldValue'];
  setValues: UseFormikHelpers<BookingFormValues>['setValues'];
  onSupplierAdded: (supplier: SupplierResponseDto) => void;
  payments: BookingExpenseFormValues[];
  setPayments: Dispatch<SetStateAction<BookingExpenseFormValues[]>>;
};

const getBookingFormEventHandlers = ({
  values,
  setFieldValue,
  setValues,
  onSupplierAdded,
  payments,
  setPayments,
}: BookingFormEventHandlerProps) => {
  const fv = values;

  const updateForm = (values: Partial<BookingFormValues>) => {
    setValues({ ...fv, ...values });
  };

  const updatePaymentsCurrency = (currency: string, exchangeRate: number) => {
    const updatedPayments = payments.map((p) =>
      p.paid
        ? p
        : {
            ...p,
            currency,
            exchangeRate,
            amountHome: (p?.amount || 0) * exchangeRate,
          },
    );

    setPayments(updatedPayments);
  };

  const handleClientChange = (
    value: ClientListItem | CreatableListItem | null,
  ) => {
    const isGroup = (value as ClientListItem)?.type === 'group';

    updateForm({
      corporateGroup: isGroup ? (value as ClientListItem) : null,
      client: isGroup ? null : (value as ClientListItem),
    });
  };

  const handleConfirmedChange = (value: boolean): void => {
    updateCommissionFieldsWithDefaultsUsingSupplierId(fv.supplier?.id);

    setFieldValue(fields.IS_CONFIRMED, value);
  };

  const handleCommissionableChange = async (value: boolean): Promise<void> => {
    setFieldValue(fields.IS_COMMISSIONABLE, value);

    updateCommissionFieldsWithDefaultsUsingSupplierId(fv.supplier?.id);

    if (!value || !fv.checkOut || !fv.supplier?.id) return;

    const supplier = await getSupplier({ id: fv.supplier.id });
    updatePaymentDue(
      fv.checkOut,
      supplier.defaultCommissionDueDateOffset ?? 45,
    );
  };

  const handlePayingEntityChange = async (entity: PayingEntity) => {
    const isCommissionable = entity === PayingEntity.CLIENT;
    updateForm({
      payingEntity: entity,
      isCommissionable,
      useMarkup: entity === PayingEntity.AGENCY,
      ...(entity === PayingEntity.AGENCY && {
        trackPayments: true,
        clientPaidTaxes: null,
      }),
    });

    if (isCommissionable && fv.checkOut && fv.supplier?.id) {
      const supplier = await getSupplier({ id: fv.supplier.id });
      updatePaymentDue(
        fv.checkOut,
        supplier.defaultCommissionDueDateOffset ?? 45,
      );
    }

    if (fv.isArc) {
      setPayments([]);
    }
  };

  const handleCommissionableValueChange = (
    commissionableValue: number,
  ): void => {
    if (commissionableValue === fv.commissionableValue) return;

    let diff: Partial<BookingFormValues> = {};

    const commissionPct = fv.commissionPct;
    const commission = fv.commission;
    if (commissionPct) {
      const commission = getCommission(
        commissionableValue || 0,
        Number(commissionPct),
      );
      diff = { ...diff, commissionableValue, commission: commission || 0 };
    } else if (commission) {
      const commissionPct = getCommissionPercentage(
        commissionableValue,
        commission || 0,
      );
      diff = { ...diff, commissionPct };
    }

    if (commissionableValue && fv.total) {
      diff.taxesAndFees = fv.total - commissionableValue;
    }

    updateForm(diff);

    setFieldValue(fields.COMMISSIONABLE_VALUE, commissionableValue);
  };

  const handleCommissionChange = (value: number) => {
    setFieldValue(fields.COMMISSION, value);
    const commissionPct = getCommissionPercentage(
      fv.commissionableValue || 0,
      value,
    );

    setFieldValue(fields.COMMISSION_PCT, commissionPct || 0);
  };

  const handleCommissionPctChange = (value: number) => {
    setFieldValue(fields.COMMISSION_PCT, value);
    const commission = getCommission(fv.commissionableValue || 0, value);

    setFieldValue(fields.COMMISSION, commission || 0);
  };

  const updateCommissionFieldsWithDefaultsUsingSupplierDto = (
    supplier: SupplierResponseDto,
  ) => {
    if (supplier.defaultCommissionPercent) {
      setFieldValue(fields.COMMISSION_PCT, supplier.defaultCommissionPercent);
      handleCommissionPctChange(supplier.defaultCommissionPercent);
    }

    if (fv.isCommissionable && fv.checkOut) {
      updatePaymentDue(
        fv.checkOut,
        supplier.defaultCommissionDueDateOffset ?? 45,
      );
    }
  };

  const updatePaymentDue = (checkOut: Date, offsetInDays: number) => {
    if (!checkOut) return;

    setFieldValue(
      fields.PAYMENT_DUE,
      moment(checkOut).startOf('day').add(offsetInDays, 'days').toDate(),
    );
  };

  const updateCommissionFieldsWithDefaultsUsingSupplierId = async (
    id?: string,
  ) => {
    if (!id) return;

    const supplier = await getSupplier({ id });
    updateCommissionFieldsWithDefaultsUsingSupplierDto(supplier);
  };

  const handleSupplierChange = (
    value: (ListItem & { type: SupplierType }) | CreatableListItem | null,
  ) => {
    updateForm({
      [fields.SUPPLIER]: value,
      [fields.SUPPLIER_TYPE]: (value as ListItem & { type: SupplierType })
        ?.type,
    });
    updateCommissionFieldsWithDefaultsUsingSupplierId(value?.id);
  };

  const handleAddNewSupplierSuccess = (supplier: SupplierResponseDto) => {
    setFieldValue(fields.SUPPLIER, {
      id: supplier.id,
      name: supplier.name,
      type: supplier.type,
    });
    updateCommissionFieldsWithDefaultsUsingSupplierDto(supplier);
    onSupplierAdded(supplier);
  };

  const handleCheckoutChange = async (value: Date | null) => {
    setFieldValue(fields.CHECK_OUT, value);
    if (!value || !fv[fields.IS_COMMISSIONABLE]) return;

    if (fv.isCommissionable && fv.supplier?.id) {
      const supplier = await getSupplier({ id: fv.supplier.id });
      updatePaymentDue(value, supplier.defaultCommissionDueDateOffset ?? 45);
    }

    if (payments.some((p) => p.paidAtCheckout)) {
      const updatedPayments = payments.map((p) => {
        const newDueDate = moment(value).toDate();
        const isBeforeToday = moment(newDueDate).isBefore(moment());
        return p.paidAtCheckout
          ? {
              ...p,
              dueDate: newDueDate,
              paid: isBeforeToday,
              paidAt: isBeforeToday ? newDueDate : undefined,
            }
          : p;
      });
      setPayments(updatedPayments);
    }
  };

  const handleCurrencyChange = async (
    currency: string | null,
    onError: (message: string) => void,
  ) => {
    let exchangeRate = 1;

    if (currency) {
      try {
        const response = await getExchangeRate(currency);
        exchangeRate = response.rate;
      } catch (error) {
        onError((error as ErrorResponseDto).message);
      }
    }

    updateForm({
      currency,
      exchangeRate,
      exchangeRateLockedAt: null,
    });

    if (currency) updatePaymentsCurrency(currency, exchangeRate);
  };

  const handleMarkupAmountChange = (value: number) => {
    const totalWithMarkup = (fv.total || 0) + value;
    const markupPct = (value / (fv.total || 0)) * 100;

    updateForm({
      markup: value,
      totalWithMarkup,
      markupPct,
    });
  };

  const handleMarkupPctChange = (value: number) => {
    const markup = (value / 100) * (fv.total || 0);
    const totalWithMarkup = (fv.total || 0) + markup;

    updateForm({
      markup,
      totalWithMarkup,
      markupPct: value,
    });
  };

  const handleTotalWithMarkupChange = (value: number) => {
    const markup = value - (fv.total || 0);
    const markupPct = (markup / (fv.total || 0)) * 100;

    updateForm({
      markup,
      totalWithMarkup: value,
      markupPct,
    });
  };

  const handleAddNewClientSuccess = (
    client: ClientResponseDto,
    isAdditionalClient = false,
  ) => {
    if (isAdditionalClient) {
      setFieldValue(
        fields.ADDITIONAL_CLIENTS,
        fv.additionalClients.concat({
          id: client.id,
          name: client.name,
        }),
      );
    } else {
      setFieldValue(fields.CLIENT, {
        id: client.id,
        name: client.name,
        type: 'client',
      });
    }
  };

  const handleTotalChange = (total: number) => {
    if (total === fv.total) return;
    if (total && fv.commissionableValue) {
      const taxesAndFees = total - fv.commissionableValue;
      setFieldValue(fields.TAXES_AND_FEES, taxesAndFees);
    }

    setFieldValue(fields.TOTAL, total);
  };

  const handleTaxesAndFeesChange = (taxesAndFees: number) => {
    if (fv.taxesAndFees === taxesAndFees) return;

    const diff = {} as Partial<BookingFormValues>;

    if (taxesAndFees && fv.total) {
      const commissionableValue = fv.total - taxesAndFees;
      diff.commissionableValue = commissionableValue;
    }

    updateForm(diff);

    setFieldValue(fields.TAXES_AND_FEES, taxesAndFees);
  };

  const handleExchangeRateChange = (newRate: number) => {
    if (newRate === fv.exchangeRate) return;
    setFieldValue(fields.EXCHANGE_RATE, newRate);
    if (fv.currency) updatePaymentsCurrency(fv.currency, newRate);
  };

  return {
    handleClientChange,
    handlePayingEntityChange,
    handleAddNewSupplierSuccess,
    handleSupplierChange,
    handleConfirmedChange,
    handleCommissionableChange,
    handleCommissionableValueChange,
    handleCommissionChange,
    handleCommissionPctChange,
    handleCurrencyChange,
    handleMarkupAmountChange,
    handleMarkupPctChange,
    handleTotalWithMarkupChange,
    handleAddNewClientSuccess,
    handleCheckoutChange,
    handleTotalChange,
    handleTaxesAndFeesChange,
    handleExchangeRateChange,
  };
};

type HandleBookingEmailParsedProps = {
  parserResults: BookingConfirmationEmailParserResponse | null;
  values: BookingFormValues;
  setValues: UseFormikHelpers<BookingFormValues>['setValues'];
};

export const handleBookingEmailParsed = async ({
  parserResults,
  values,
  setValues,
}: HandleBookingEmailParsedProps) => {
  if (!parserResults) return;

  const diff: Partial<BookingFormValues> = {};

  if (parserResults.destinationGuess) {
    diff[fields.DESTINATIONS] = [
      {
        id: parserResults.destinationGuess.id.toString(),
        name: parserResults.destinationGuess.name,
      },
    ];
  }

  if (parserResults.confirmationNumber) {
    diff[fields.CONFIRMATION_NUMBER] = parserResults.confirmationNumber;
  }

  if (parserResults.checkInDate) {
    diff[fields.CHECK_IN] = moment(parserResults.checkInDate).toDate();
  }

  if (parserResults.checkOutDate) {
    diff[fields.CHECK_OUT] = moment(parserResults.checkOutDate).toDate();
  }

  if (parserResults.currency) {
    diff[fields.CURRENCY] = parserResults.currency;

    try {
      const exchange = await getExchangeRate(diff[fields.CURRENCY]);
      diff[fields.EXCHANGE_RATE] = exchange.rate || 1;
    } catch (error) {
      console.error(error);
    }
  }

  if (parserResults.total) {
    diff[fields.TOTAL] = parserResults.total;
  }
  if (values[fields.IS_COMMISSIONABLE] && parserResults.taxesAndFeesAmount) {
    diff[fields.TAXES_AND_FEES] = parserResults.taxesAndFeesAmount;
  }

  if (
    values[fields.IS_COMMISSIONABLE] &&
    parserResults.total &&
    parserResults.taxesAndFeesAmount
  ) {
    diff[fields.COMMISSIONABLE_VALUE] =
      parserResults.total - parserResults.taxesAndFeesAmount;
  }

  if (parserResults.clientGuess) {
    const { id, name, email } = parserResults.clientGuess;
    diff[fields.CLIENT] = { id, name, email, type: 'client' };
  }

  if (parserResults.supplierGuess) {
    const { id, name, type, defaultCommissionPercent } =
      parserResults.supplierGuess;

    diff[fields.SUPPLIER] = { id, name, type };
    diff[fields.SUPPLIER_TYPE] = type;

    if (defaultCommissionPercent) {
      if (diff[fields.COMMISSIONABLE_VALUE]) {
        const commission = getCommission(
          diff[fields.COMMISSIONABLE_VALUE],
          defaultCommissionPercent,
        );
        diff[fields.COMMISSION] = commission || 0;
      }

      diff[fields.COMMISSION_PCT] = defaultCommissionPercent;
    }
  }

  diff[fields.PAYMENT_DUE] = moment(parserResults.checkOutDate)
    .startOf('day')
    .add(
      parserResults?.supplierGuess?.defaultCommissionDueDateOffset ?? 45,
      'days',
    )
    .toDate();

  setValues({ ...values, ...diff });
};

export default getBookingFormEventHandlers;
