import AttachmentsTab from 'components/common/AttachmentsTab/AttachmentsTab';
import { EntityType } from 'components/trips/attachments/types';
import useBooking from 'hooks/useBooking';

type BookingAttachmentsProps = {
  bookingId: string | undefined;
};

export default function BookingAttachments({
  bookingId,
}: BookingAttachmentsProps) {
  const { data: booking, refresh } = useBooking({ bookingId });
  return (
    <AttachmentsTab
      attachments={booking?.attachments || []}
      entityType={EntityType.BOOKING}
      entityId={bookingId || ''}
      refresh={refresh}
      showAddButton
    />
  );
}
