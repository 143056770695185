import { AutoAwesome } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import type { BookingConfirmationEmailParserResponse } from 'dtos/bookingEmailParsing.dtos';
import { useState } from 'react';
import ky from 'utils/client/ky';

type BookingEmailParserButtonProps = {
  tripId: string;
  onParse: (details: BookingConfirmationEmailParserResponse) => void;
};

export default function BookingEmailParserButton({
  tripId,
  onParse,
}: BookingEmailParserButtonProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [emailBody, setEmailBody] = useState('');
  const [processing, setProcessing] = useState(false);

  const handleParse = async () => {
    setProcessing(true);
    try {
      const parserResults = await ky
        .post(`/api/bookings/from-email?tripId=${tripId}`, {
          json: { email: emailBody },
        })
        .json<BookingConfirmationEmailParserResponse>();

      setDialogOpen(false);
      setEmailBody('');
      onParse(parserResults);
    } catch (e) {
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <IconButton onClick={() => setDialogOpen(true)}>
        <AutoAwesome />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setEmailBody('');
          setDialogOpen(false);
        }}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            minHeight: 'min(500px, calc(100vh - 60px))',
          },
        }}
      >
        <DialogTitle>Paste Supplier Confirmation Email</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            value={emailBody}
            onChange={(e) => setEmailBody(e.target.value)}
            fullWidth
            autoFocus
            label="Email Body"
            sx={{ mt: 1 }}
            InputProps={{
              inputComponent: TextareaAutosize,
            }}
            inputProps={{
              minRows: 10,

              sx: {
                minHeight: 400,
                maxHeight: 400,
                overflow: 'auto!important',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleParse}
            variant="contained"
            color="primary"
            disabled={!emailBody || processing}
          >
            {processing ? (
              <>
                <CircularProgress size={18} style={{ marginRight: 8 }} />
                Extracting Details from Email...
              </>
            ) : (
              'Extract Details from Email'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
