import { roundToTwoDecimals } from 'utils/client/formatting';
import { useTypedFormikContext } from 'utils/client/formik';
import type { BookingFormValues } from '../schema';
import type { BookingExpenseFormValues } from './paymentSchema';

const getPaymentDialogHandlers = ({
  bookingFormValues,
}: {
  bookingFormValues: BookingFormValues;
}) => {
  const { setValues, values } =
    useTypedFormikContext<BookingExpenseFormValues>();

  return {
    handleAmountChange: (value: number | null) => {
      setValues({
        ...values,
        amountHome: convertForeignAmountToHomeAmount(
          value,
          values.exchangeRate,
        ),
      });
    },

    handleLocalAmountChange: (amountHome: number | null) => {
      if (values.amountHome === amountHome) return; //prevent floating point weirdness
      const exchangeRate =
        amountHome && values.amount ? amountHome / values.amount : 1;
      setValues({
        ...values,
        exchangeRate,
        amountHome,
      });
    },
    handleExchangeRateChange: (value: string | number) => {
      setValues({
        ...values,
        exchangeRate: value as number,
        amountHome: convertForeignAmountToHomeAmount(
          values.amount,
          value as number,
        ),
      });
    },

    handlePaidAtCheckoutChange: (checked: boolean) => {
      const updatedValues = { ...values };

      updatedValues.paidAtCheckout = checked;

      if (checked) {
        updatedValues.dueDate = bookingFormValues?.checkOut;

        if (
          bookingFormValues?.checkOut &&
          bookingFormValues.checkOut < new Date()
        ) {
          updatedValues.paid = true;
          updatedValues.paidAt = new Date(bookingFormValues?.checkOut);
        }
      }

      setValues(updatedValues);
    },

    handlePaidAtChange: (newValue: Date | null) => {
      const updatedValues = { ...values };
      updatedValues.paidAt = newValue || undefined;
      if (!values.dueDate) updatedValues.dueDate = newValue;

      setValues(updatedValues);
    },
  };
};

export default getPaymentDialogHandlers;

const convertForeignAmountToHomeAmount = (
  foreignAmount: number | null,
  exchangeRate: number | null,
) => {
  if (!foreignAmount || !exchangeRate) return 0;

  const homeCurrencyValue = roundToTwoDecimals(foreignAmount * exchangeRate);
  return homeCurrencyValue;
};
