import type { TypographyProps } from '@mui/material';
import T from 'components/common/T';
import {
  ChangeEventType,
  type ChangeLogEventDto,
} from 'services/changeLog/types';
import { formatUpdateEventValue } from './utils';

const InlineT = ({ children, ...props }: TypographyProps) => {
  return (
    <T component="span" display="inline" {...props}>
      {children}
    </T>
  );
};

type EventLabelProps = {
  event: ChangeLogEventDto;
};

export const EventLabel = ({ event }: EventLabelProps) => {
  switch (event.type) {
    case ChangeEventType.CREATE:
      return <InlineT>created {event.entityName.toLowerCase()}</InlineT>;
    case ChangeEventType.UPDATE: {
      const { oldValue, newValue } = formatUpdateEventValue(event);

      return (
        <InlineT>
          updated{' '}
          <InlineT color="text.primary">
            {event.field.label.toLowerCase()}
          </InlineT>{' '}
          from <InlineT color="text.primary">{oldValue}</InlineT> to{' '}
          <InlineT color="text.primary">{newValue}</InlineT>
        </InlineT>
      );
    }
    case ChangeEventType.DELETE:
      return <InlineT>deleted {event.entityName.toLowerCase()}</InlineT>;
    case ChangeEventType.MISC:
      return <InlineT>{event.label}</InlineT>;
    default:
      return null;
  }
};
