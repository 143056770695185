import { ExtendedFormDialog } from '@customFormik';
import { ErrorResponseDto, type SplitResponseDto } from 'dtos';
import { getBookingDefaultSplits } from 'requests/bookings';
import BookingSplitForm from './BookingSplitForm';
import formSchema from './schema';

type BookingSplitsDialogProps = {
  bookingId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  mode: 'add' | 'edit';
  split?: SplitResponseDto;
  onSplitAdded: (split: SplitResponseDto[]) => void;
  excludeAgencyUserIds?: string[];
};

export default function BookingSplitsDialog({
  bookingId,
  open = true,
  setOpen,
  mode,
  split,
  onSplitAdded,
  excludeAgencyUserIds = [],
}: BookingSplitsDialogProps) {
  return (
    <ExtendedFormDialog
      title={mode === 'add' ? 'Add Split' : 'Edit Split'}
      width={500}
      entity={split}
      open={open}
      setOpen={setOpen}
      onSave={async (data: SplitResponseDto) => {
        if (!data.id) {
          return {} as SplitResponseDto;
        }

        const splits = await getBookingDefaultSplits({
          bookingId,
          agencyUserId: data.agencyUserId,
        });

        const newSplits = splits.reduce(
          (acc, currentSplit) => [
            ...acc,
            {
              ...currentSplit,
              takePercent:
                !currentSplit.agencyId &&
                currentSplit.agencyUserId === data.agencyUserId
                  ? data.takePercent
                  : currentSplit.takePercent,
            },
          ],
          [] as SplitResponseDto[],
        );

        onSplitAdded(newSplits);

        return {} as SplitResponseDto;
      }}
      onSuccess={(_split) => setOpen(false)}
      FormComponent={() => (
        <BookingSplitForm excludeAgencyUserIds={excludeAgencyUserIds} />
      )}
      formSchema={formSchema}
    />
  );
}
