import { ChangeLogTimeline } from 'components/common/changeLogTimeline/ChangeLogTimeline';
import { ChangeLogEntityType } from 'services/changeLog/types';

type BookingActivityProps = {
  bookingId: string | undefined;
};

export const BookingActivity = ({ bookingId }: BookingActivityProps) => {
  if (!bookingId) {
    return null;
  }

  return (
    <ChangeLogTimeline
      entityId={bookingId}
      type={ChangeLogEntityType.BOOKING}
    />
  );
};
