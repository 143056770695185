import { roundToTwoDecimals } from 'utils/client/formatting';
import type { DepositAndFinalFormValues } from './schema';

const getDepositAndFinalDialogHandlers = ({
  values,
  setValues,
  bookingAmount,
}: {
  values: DepositAndFinalFormValues;
  setValues: (values: DepositAndFinalFormValues) => void;
  bookingAmount: number;
}) => {
  const convertForeignAmountToHomeAmount = (
    foreignAmount: number | null,
    exchangeRate: number | null,
  ) => {
    if (!foreignAmount || !exchangeRate) return 0;
    const homeCurrencyValue = roundToTwoDecimals(foreignAmount * exchangeRate);
    return homeCurrencyValue ?? 0;
  };

  return {
    handleDepositAmountChange: (value: number) => {
      if (!bookingAmount) return;

      const depositHomeAmount = convertForeignAmountToHomeAmount(
        value,
        values.depositConversionRate,
      );

      const finalAmount =
        value <= bookingAmount ? roundToTwoDecimals(bookingAmount - value) : 0;

      const finalHomeAmount = convertForeignAmountToHomeAmount(
        finalAmount,
        values.finalConversionRate,
      );

      setValues({
        ...values,
        depositHomeAmount,
        finalAmount,
        finalHomeAmount,
      });
    },

    handleDepositHomeAmountChange: (depositHomeAmount: number) => {
      if (values.depositHomeAmount === depositHomeAmount) return; //prevent floating point weirdness
      const depositConversionRate =
        depositHomeAmount && values.depositAmount
          ? depositHomeAmount / values.depositAmount
          : 1;

      setValues({
        ...values,
        depositConversionRate,
        depositHomeAmount,
      });
    },

    handleDepositConversionRateChange: (value: number) => {
      setValues({
        ...values,
        depositConversionRate: value,
        depositHomeAmount: convertForeignAmountToHomeAmount(
          values.depositAmount,
          value,
        ),
      });
    },

    handleFinalAmountChange: (value: number) => {
      if (!bookingAmount) return;

      const finalHomeAmount = convertForeignAmountToHomeAmount(
        value,
        values.finalConversionRate,
      );

      const depositAmount =
        value <= bookingAmount ? roundToTwoDecimals(bookingAmount - value) : 0;

      const depositHomeAmount = convertForeignAmountToHomeAmount(
        depositAmount,
        values.depositConversionRate,
      );

      setValues({
        ...values,
        finalHomeAmount,
        depositAmount,
        depositHomeAmount,
      });
    },

    handleFinalHomeAmountChange: (finalHomeAmount: number) => {
      if (values.finalHomeAmount === finalHomeAmount) return; //prevent floating point weirdness
      const finalConversionRate =
        finalHomeAmount && values.finalAmount
          ? finalHomeAmount / values.finalAmount
          : 1;

      setValues({
        ...values,
        finalConversionRate,
        finalHomeAmount,
      });
    },

    handleFinalConversionRateChange: (value: number) => {
      setValues({
        ...values,
        finalConversionRate: value,
        finalHomeAmount: convertForeignAmountToHomeAmount(
          values.finalAmount,
          value,
        ),
      });
    },
  };
};

export default getDepositAndFinalDialogHandlers;
