import { USD } from 'data/currencies';
import {
  type BookingExpenseResponseDto,
  type BookingExpenseUpdateRequestDto,
  type BookingExpenseUpsertRequestDto,
  PaymentMethod,
} from 'dtos';
import type { FormikValues } from 'formik';
import { type ExtendedFormSchema, createYupSchema } from 'utils/client/types';
import * as yup from 'yup';
import type { BookingFormValues } from '../schema';

export type ContextValues = {
  useCustomAgencyPaymentMethods: boolean;
  bookingFormValues: BookingFormValues;
  defaultSubject?: string;
};

export enum FieldNames {
  SUBJECT = 'subject',
  AMOUNT = 'amount',
  AMOUNT_HOME = 'amountHome',
  PAYMENT_METHOD = 'paymentMethod',
  BILL_PAYMENT_METHOD_ID = 'billPaymentMethodId',
  DUE_DATE = 'dueDate',
  PAID_AT_CHECKOUT = 'paidAtCheckout',
  PAID = 'paid',
  PAID_AT = 'paidAt',
  NOTES = 'notes',
  LOCKED = 'locked',
  CURRENCY = 'currency',
  EXCHANGE_RATE = 'exchangeRate',
  EXCHANGE_RATE_LOCKED_AT = 'exchangeRateLockedAt',
}

export interface BookingExpenseFormValues extends FormikValues {
  id?: string;
  subject: string;
  amount: number | null;
  amountHome: number | null;
  paymentMethod: string;
  billPaymentMethodId?: string;
  dueDate: Date | null;
  paidAtCheckout: boolean;
  paid: boolean;
  paidAt?: Date;
  notes?: string;
  locked: boolean;
  currency: string;
  exchangeRate: number;
  exchangeRateLockedAt?: Date;
  isArc?: boolean;
}

export const schema = ({ useCustomAgencyPaymentMethods }: ContextValues) => {
  const paymentMethodSchema = !useCustomAgencyPaymentMethods
    ? yup.string().label('Payment Method').required()
    : yup.string().label('Payment Method');

  const billPaymentMethodIdSchema = useCustomAgencyPaymentMethods
    ? yup.string().label('Payment Method').required()
    : yup.string().label('Payment Method');

  return createYupSchema<BookingExpenseFormValues>({
    subject: yup.string().optional().label('Subject'),
    amount: yup.number().min(0).required().label('Payment Amount'),
    amountHome: yup.number().min(0).nullable().label('Payment Local Amount'),
    paymentMethod: paymentMethodSchema,
    billPaymentMethodId: billPaymentMethodIdSchema,
    dueDate: yup
      .date()
      .required()
      .typeError('Payment Due is required')
      .label('Payment Due'),
    paidAt: yup
      .date()
      .label('Payment Made')
      .typeError('Payment Made is required')
      .when('paid', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    notes: yup.string().nullable().label('Notes'),
    currency: yup.string().label('Currency'),
    exchangeRate: yup.number().positive().required().label('Exchange Rate'),
    exchangeRateLockedAt: yup.date().nullable().label('Exchange Rate Locked'),
    locked: yup.boolean().label('Locked'),
    paid: yup.boolean().label('Paid'),
    paidAtCheckout: yup.boolean().label('Paid at Checkout'),
  });
};

const getDefaultValues = (
  payment: BookingExpenseFormValues | undefined,
  context?: ContextValues,
): BookingExpenseFormValues => {
  return {
    id: payment?.id,
    subject: payment?.subject ?? context?.defaultSubject ?? '',
    amount: payment?.amount ?? null,
    amountHome: payment?.amountHome ?? null,
    paymentMethod: payment?.paymentMethod ?? PaymentMethod.CREDIT_CARD,
    billPaymentMethodId: payment?.billPaymentMethodId ?? undefined,
    dueDate: payment?.dueDate || null,
    paidAtCheckout: payment?.paidAtCheckout ?? false,
    paid: payment?.paid ?? false,
    paidAt: payment?.paidAt ?? undefined,
    notes: payment?.notes ?? undefined,
    locked: payment?.locked ?? false,
    currency: payment?.currency ?? context?.bookingFormValues?.currency ?? USD,
    exchangeRate:
      payment?.exchangeRate ||
      Number(context?.bookingFormValues?.exchangeRate || 1),
    exchangeRateLockedAt:
      payment?.exchangeRateLockedAt ??
      context?.bookingFormValues?.exchangeRateLockedAt ??
      undefined,
    isArc: context?.bookingFormValues.isArc ?? false,
  };
};

export const mapExpenseResponseDtoToFormValues = (
  expense: BookingExpenseResponseDto,
): BookingExpenseFormValues => {
  return {
    id: expense.id,
    subject: expense?.subject || '',
    amount: expense?.amount || 0,
    amountHome: expense?.amountHome || 0,
    paymentMethod:
      (expense as BookingExpenseResponseDto)?.billPaymentMethod?.type ||
      expense?.paymentMethod ||
      PaymentMethod.CREDIT_CARD,
    billPaymentMethodId:
      (expense as BookingExpenseResponseDto)?.billPaymentMethod?.id ??
      (expense as BookingExpenseUpdateRequestDto)?.billPaymentMethodId ??
      undefined,
    dueDate: new Date(expense?.dueDate),
    paidAt: expense.paidAt ? new Date(expense?.paidAt) : undefined,
    paid: !!expense?.paidAt,
    locked: (expense && 'locked' in expense && expense.locked) || false,
    notes: expense?.notes,
    currency: expense.currency as string,
    exchangeRate: expense?.exchangeRate || 1,
    exchangeRateLockedAt: expense?.exchangeRateLockedAt
      ? new Date(expense?.exchangeRateLockedAt)
      : undefined,
    paidAtCheckout: expense?.isPaidAtCheckout || false,
  };
};

export const mapExpenseFormValuesToDto = (
  payment: BookingExpenseFormValues,
): BookingExpenseUpsertRequestDto => ({
  id: payment.id,
  subject: payment.subject,
  amount: payment.amount as number,
  paymentMethod: payment.paymentMethod as PaymentMethod,
  billPaymentMethodId: payment.billPaymentMethodId,
  dueDate: payment.dueDate?.toISOString() as string,
  paidAt: payment.paidAt?.toISOString(),
  notes: payment.notes,
  currency: payment.currency,
  exchangeRate: Number(payment.exchangeRate),
  exchangeRateLockedAt: payment?.exchangeRateLockedAt?.toISOString(),
  isPaidAtCheckout: payment.paidAtCheckout,
});

const getEntityFromFormValues = (
  values: BookingExpenseFormValues,
): BookingExpenseFormValues => ({ ...values });

const paymentSchema: ExtendedFormSchema<
  BookingExpenseFormValues,
  BookingExpenseFormValues,
  BookingExpenseFormValues,
  typeof schema,
  ContextValues
> = {
  schema,
  getDefaultValues,
  getEntityFromFormValues,
};

export default paymentSchema;
