import { Box, Button, CircularProgress, Divider } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SabreTab from 'components/bookings/BookingDialog/tabs/SabreTab';
import { ConfirmationDialog } from 'components/common/ConfirmationDialog';
import T from 'components/common/T';
import TabPanel from 'components/common/TabPanel';
import { BookingTab } from 'components/types';
import { useUserInfoContext } from 'context/user-info.context';
import {
  type BookingResponseDto,
  type GroupedSplitsResponseDto,
  PayingEntity,
} from 'dtos';
import type { TripAndBookingCreateRequestDto } from 'dtos/booking.dtos';
import { Formik } from 'formik';
import { useTripBooking, useUserAgencies } from 'hooks';
import useTrip from 'hooks/useTrip';
import moment from 'moment-timezone';
import { useRouter } from 'next/router';
import { enqueueSnackbar as toast } from 'notistack';
import { useEffect, useState } from 'react';
import {
  createBooking,
  createTripAndBooking,
  updateBooking,
} from 'requests/bookings';
import { sumArray } from 'utils/client/math';
import BookingDialogTitle from './BookingDialogTitle';
import { handleBookingEmailParsed } from './bookingFormEventHandlers';
import {
  type BookingFormValues,
  FieldNames,
  getBookingFromFormValues,
  getDefaultValues,
  schema,
} from './schema';
import AdvisorsAndSplitsTab from './tabs/AdvisorsAndSplitsTab';
import CommissionsTab from './tabs/CommissionsTab';
import { BookingActivity } from './tabs/activity/BookingActivity';
import BookingAttachmentsTab from './tabs/attachments/BookingAttachments';
import BookingDetailsForm from './tabs/details/BookingDetailsForm';
import {
  type BookingExpenseFormValues,
  mapExpenseFormValuesToDto,
  mapExpenseResponseDtoToFormValues,
} from './tabs/paymentSchema';
import { calculateTotalAssignedPct } from './tabs/splits/BookingCommissionSplits';
import useBookingDialogActionHandlers from './useBookingDialogActionHandlers';

type BookingDialogProps = {
  tripId?: string;
  mode: 'add' | 'edit';
  isQuickAdd?: boolean;
  showTripSelector?: boolean;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  bookingId?: string;
  onSuccess: (mode: 'add' | 'edit', booking: BookingResponseDto) => void;
  hideSuccessToast?: boolean;
  initialTab?: BookingTab;
  bookingTemplate?: Partial<BookingResponseDto>;
};

export default function BookingDialog({
  tripId,
  open,
  setOpen,
  mode,
  isQuickAdd,
  showTripSelector,
  bookingId,
  onSuccess = () => null,
  hideSuccessToast = false,
  initialTab = BookingTab.BOOKING,
  bookingTemplate,
}: BookingDialogProps) {
  const userInfo = useUserInfoContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const router = useRouter();
  const [tab, setTab] = useState<BookingTab>(initialTab);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [bookingCommissionSplits, setBookingCommissionSplits] =
    useState<GroupedSplitsResponseDto>();

  const {
    data: booking,
    isLoading: isTripBookingLoading,
    clearCacheEntry: clearBookingCacheEntry,
    refresh: refreshBooking,
  } = useTripBooking({
    bookingId,
  });
  const { trip, isLoading: isTripLoading } = useTrip(tripId as string);

  const actionHandlers = useBookingDialogActionHandlers({
    bookingId: booking?.id,
    refreshBooking,
  });

  const { agencies: userAgencies } = useUserAgencies({
    getAll: true,
    sortModel: { sort: 'lastUsed', sortDirection: 'desc' },
    userId: trip?.advisorUserId,
  });

  const [payments, setPayments] = useState<BookingExpenseFormValues[]>(
    (booking?.expenses?.data || []).map(mapExpenseResponseDtoToFormValues),
  );

  useEffect(() => {
    const updatePaymentsFromBooking = () => {
      if (booking?.expenses?.data)
        setPayments(
          booking.expenses.data.map(mapExpenseResponseDtoToFormValues),
        );
    };

    updatePaymentsFromBooking();
  }, [booking?.expenses?.data]);

  useEffect(() => {
    if (booking?.splits) {
      setBookingCommissionSplits(booking?.splits);
    }
  }, [booking?.splits]);

  useEffect(() => {
    const defaultTab = router.query.defaultTab;
    if (defaultTab !== undefined) {
      const tabValue = Number(defaultTab);
      if (Object.values(BookingTab).includes(tabValue)) {
        setTab(tabValue);
      }
    }
  }, [router.query.defaultTab]);

  const onCommissionSplitsChange = (newSplits: GroupedSplitsResponseDto) =>
    setBookingCommissionSplits(newSplits);

  const hasSplitsTabError =
    bookingCommissionSplits &&
    calculateTotalAssignedPct(bookingCommissionSplits) !==
      bookingCommissionSplits?.maxPercent;

  const isConfirmedLocked = booking?.isConfirmedLocked;

  const handleOnSubmit = async (values: BookingFormValues) => {
    if (mode === 'edit') {
      const expensesHaveDifferentCurrency =
        values.currency &&
        booking?.expenses?.data.some(
          (expense) => expense.currency && expense.currency !== values.currency,
        );

      if (expensesHaveDifferentCurrency) {
        setIsConfirmationDialogOpen(true);
        return;
      }
    }

    if (hasSplitsTabError && userInfo?.isOrgUser) {
      return setTab(BookingTab.ADVISORS_AND_SPLITS);
    }

    return submit(values);
  };

  const submit = async (values: BookingFormValues) => {
    const b = getBookingFromFormValues(values);

    if (
      userInfo?.isOrgUser &&
      !userInfo.isOrgAssistant &&
      bookingCommissionSplits
    ) {
      b.splits = [
        ...bookingCommissionSplits.primarySplits,
        ...bookingCommissionSplits.secondarySplits.flat(),
      ];
    }

    b.expenses = payments.map(mapExpenseFormValuesToDto);

    try {
      const result = isQuickAdd
        ? await createTripAndBooking(b as TripAndBookingCreateRequestDto)
        : mode === 'add'
          ? await createBooking(b, b.tripId ?? (tripId as string))
          : await updateBooking(b, bookingId as string);

      onSuccess(mode, result);
      setOpen(false);
      clearBookingCacheEntry();
      if (!hideSuccessToast) {
        if (isQuickAdd) {
          toast('Trip and booking created successfully');
        } else {
          toast(
            `Booking ${mode === 'add' ? 'created' : 'updated'} successfully`,
          );
        }
      }
    } catch (e) {}
  };

  const isLoading = isTripBookingLoading || isTripLoading;

  const close = (resetForm: () => void) => {
    setOpen(false);
    resetForm();
  };

  const isDialogDisabled =
    (!!booking?.locked && !userInfo?.isOrgUser) || !!booking?.voidedAt;

  const bookingInitialValues =
    bookingTemplate && mode === 'add' ? bookingTemplate : booking;

  return (
    <Formik
      initialValues={getDefaultValues({
        booking: bookingInitialValues,
        primaryClient: trip?.primaryClient
          ? {
              id: trip.primaryClient.id,
              name: trip.primaryClient.name,
              type: 'client',
            }
          : undefined,
        corporateGroup: trip?.corporateGroup
          ? {
              id: trip.corporateGroup.id,
              name: trip.corporateGroup.name,
              type: 'group',
            }
          : undefined,
        homeCurrencyCode: userInfo?.homeCurrency?.code,
        isQuickAdd,
        userAgencies,
      })}
      validationSchema={schema({ isQuickAdd, showTripSelector })}
      validate={(values) => {
        const errors: Partial<Record<FieldNames, string>> = {};

        const bookingHasRefund =
          booking?.refundedAmount && booking.originalTotal !== booking.total;

        if (
          values.trackPayments &&
          values.payingEntity === PayingEntity.AGENCY &&
          sumArray(payments, 'amount') !== values[FieldNames.TOTAL] &&
          !bookingHasRefund
        ) {
          errors[FieldNames.TRACK_PAYMENTS] =
            'Payments total must match the total fare';
        }

        return errors;
      }}
      enableReinitialize
      onSubmit={handleOnSubmit}
    >
      {({
        handleSubmit,
        values,
        setValues,
        isSubmitting,
        isValid,
        resetForm,
        submitCount,
        errors,
      }) => {
        const showCommissionsTabError =
          !isQuickAdd && // Commissions tab is not shown in quick add
          errors[FieldNames.PAYMENT_DUE] &&
          tab !== BookingTab.COMMISSION &&
          submitCount > 0;

        return (
          <>
            <Dialog
              fullScreen={fullScreen}
              fullWidth={true}
              open={open}
              aria-labelledby="booking-dialog"
              PaperProps={{
                sx: {
                  minHeight: 'min(760px, calc(100vh - 60px))',
                  minWidth: 900,
                },
              }}
            >
              <BookingDialogTitle
                mode={mode}
                isQuickAdd={isQuickAdd}
                booking={booking}
                tripId={tripId as string}
                tab={tab}
                setTab={setTab}
                isValid={isValid}
                hasSplitsTabError={!!hasSplitsTabError}
                showCommissionsTabError={!!showCommissionsTabError}
                values={values}
                onParseEmail={async (parserResults) => {
                  await handleBookingEmailParsed({
                    parserResults,
                    values,
                    setValues,
                  });
                }}
                onActionClick={({ action }) =>
                  actionHandlers.onActionClick({ action, onSuccess })
                }
              />

              <DialogContent
                sx={{
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {isLoading ? (
                  <CircularProgress sx={{ m: 'auto' }} />
                ) : (
                  <Box overflow={'auto'} flex={1} p={3}>
                    <TabPanel value={tab} index={BookingTab.BOOKING}>
                      <BookingDetailsForm
                        mode={bookingId ? 'edit' : 'add'}
                        isQuickAdd={isQuickAdd}
                        showTripSelector={showTripSelector}
                        onSupplierAdded={() => {}}
                        isConfirmedLocked={isConfirmedLocked}
                        includeCorporateGroupsAsClients={
                          trip?.isCorporateProgram
                        }
                        payments={payments}
                        setPayments={setPayments}
                        bookingVoidedDate={
                          booking?.voidedAt
                            ? moment(booking.voidedAt).toDate()
                            : undefined
                        }
                        disabled={isDialogDisabled}
                        paymentsDisabled={
                          (isDialogDisabled &&
                            values.payingEntity === PayingEntity.AGENCY) ||
                          !!booking?.voidedAt
                        }
                        userAgencies={userAgencies ?? []}
                      />
                    </TabPanel>
                    {!isQuickAdd && (
                      <>
                        {userInfo?.isOrgUser && !userInfo.isOrgAssistant && (
                          <TabPanel
                            value={tab}
                            index={BookingTab.ADVISORS_AND_SPLITS}
                          >
                            <AdvisorsAndSplitsTab
                              bookingId={booking?.id as string}
                              commissionSplits={bookingCommissionSplits}
                              onCommissionSplitsChange={
                                onCommissionSplitsChange
                              }
                            />
                          </TabPanel>
                        )}
                        <TabPanel value={tab} index={BookingTab.COMMISSION}>
                          <CommissionsTab
                            booking={booking}
                            disabled={isDialogDisabled}
                          />
                        </TabPanel>
                        {booking?.pnr ? (
                          <TabPanel value={tab} index={BookingTab.SABRE}>
                            <SabreTab bookingId={booking?.id as string} />
                          </TabPanel>
                        ) : null}
                        {process.env.NEXT_PUBLIC_ATTACHMENTS_ENABLED === '1' &&
                          mode === 'edit' && (
                            <TabPanel
                              value={tab}
                              index={BookingTab.ATTACHMENTS}
                            >
                              <BookingAttachmentsTab bookingId={booking?.id} />
                            </TabPanel>
                          )}
                        <TabPanel value={tab} index={BookingTab.ACTIVITY}>
                          <BookingActivity bookingId={bookingId} />
                        </TabPanel>
                      </>
                    )}
                  </Box>
                )}
              </DialogContent>

              <Divider />

              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  onClick={() => close(resetForm)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || isLoading}
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  {isQuickAdd
                    ? 'Create Trip & Booking'
                    : mode === 'add'
                      ? 'Create Booking'
                      : 'Update Booking'}
                </Button>
              </DialogActions>
            </Dialog>
            <ConfirmationDialog
              open={isConfirmationDialogOpen}
              title={'Edit Booking'}
              message={
                <T>
                  This booking has scheduled payments in a different currency.
                </T>
              }
              confirmButtonMessage="Update Booking"
              confirmButtonColor="primary"
              cancelButtonVariant="text"
              cancelButtonColor="primary"
              setOpen={setIsConfirmationDialogOpen}
              onCancel={() => setIsConfirmationDialogOpen(false)}
              onConfirm={() => submit(values)}
            />
          </>
        );
      }}
    </Formik>
  );
}
