import {
  AgencySelector,
  type ClientListItem,
  ClientMultiselect,
  ClientSelector,
  ConversionRateField,
  CurrencySelector,
  DatePicker,
  DestinationMultiselect,
  MoneyTextField,
  PaymentTypeSelector,
  PercentTextField,
  Switch,
  TextField,
} from '@customFormik';
import { Divider, FormControl, Stack } from '@mui/material';
import SupplierSelector from 'components/common/forms/formik/SupplierSelector';
import SupplierTypeSelector from 'components/common/forms/formik/SupplierTypeSelector';
import TripSelector from 'components/common/forms/formik/TripSelector';
import { useUserInfoContext } from 'context/user-info.context';
import { getCurrencyByCode } from 'data/currencies';
import {
  type AgencyResponseDto,
  PayingEntity,
  type SupplierResponseDto,
  SupplierType,
} from 'dtos';
import { enqueueSnackbar as toast } from 'notistack';
import { type Dispatch, type SetStateAction, useEffect } from 'react';
import { useTypedFormikContext } from 'utils/client/formik';
import getBookingFormEventHandlers from '../../bookingFormEventHandlers';
import { type BookingFormValues, FieldNames as fields } from '../../schema';
import type { BookingExpenseFormValues } from '../paymentSchema';
import BookingFormSupplierPayments from './BookingFormSupplierPayments';

type BookingFormProps = {
  mode: 'add' | 'edit';
  isQuickAdd?: boolean;
  showTripSelector?: boolean;
  onSupplierAdded: (supplier: SupplierResponseDto) => void;
  isConfirmedLocked?: boolean;
  includeCorporateGroupsAsClients?: boolean;
  payments: BookingExpenseFormValues[];
  setPayments: Dispatch<SetStateAction<BookingExpenseFormValues[]>>;
  bookingVoidedDate?: Date;
  disabled?: boolean;
  isBookingVoided?: boolean;
  paymentsDisabled?: boolean;
  userAgencies: AgencyResponseDto[];
};

export default function BookingDetailsForm({
  mode,
  isQuickAdd,
  showTripSelector,
  onSupplierAdded,
  isConfirmedLocked,
  includeCorporateGroupsAsClients = false,
  payments,
  setPayments,
  bookingVoidedDate,
  disabled = false,
  paymentsDisabled = false,
  userAgencies,
}: BookingFormProps) {
  const userInfo = useUserInfoContext();

  const {
    values: fv,
    setFieldValue,
    setValues,
    errors,
    validateForm,
  } = useTypedFormikContext<BookingFormValues>();

  const h = getBookingFormEventHandlers({
    values: fv,
    setFieldValue,
    setValues,
    onSupplierAdded,
    setPayments,
    payments,
  });

  useEffect(() => {
    validateForm?.(fv);
  }, [payments]);

  const convertToHomeCurrency = (value: number | null) => {
    if (
      !fv.exchangeRate ||
      fv.currency === userInfo?.homeCurrency?.code ||
      !value
    ) {
      return null;
    }

    const homeCurrencyValue = value * fv.exchangeRate;
    return Math.round(homeCurrencyValue * 100) / 100;
  };

  const hasPaidPayments = payments.some((payment) => payment.paid);
  const currentCurrency = getCurrencyByCode(fv.currency);

  if (!userAgencies) return null;

  return (
    <>
      <Stack spacing={2}>
        {isQuickAdd && userAgencies.length > 1 && (
          <AgencySelector
            label="Agency"
            name={fields.AGENCY}
            helperText="Select an agency for this trip and booking"
            required
          />
        )}
        <Switch
          name={fields.IS_CONFIRMED}
          label="Confirmed Booking"
          disabled={disabled || isConfirmedLocked}
        />
        {isQuickAdd && (
          <DestinationMultiselect
            name={fields.DESTINATIONS}
            label="Destinations"
            required
            disabled={disabled}
          />
        )}
        {showTripSelector && (
          <TripSelector
            name={fields.TRIP}
            label="Trip"
            required
            disabled={disabled}
          />
        )}
        <Stack direction="row" spacing={2}>
          <ClientSelector
            name={fields.CLIENT}
            fieldId={fields.CLIENT}
            label="Client"
            required
            showGroups
            includeCorporateGroups={includeCorporateGroupsAsClients}
            allowAdd
            onAddSuccess={(client) => h.handleAddNewClientSuccess(client)}
            value={(fv.client || fv.corporateGroup) as ClientListItem}
            onChange={h.handleClientChange}
            disabled={disabled}
          />
          <ClientMultiselect
            name={fields.ADDITIONAL_CLIENTS}
            fieldId={fields.ADDITIONAL_CLIENTS}
            label="Additional Clients"
            allowAdd
            onAddSuccess={(client) => h.handleAddNewClientSuccess(client, true)}
            showGroups
            disabled={disabled}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <SupplierSelector
            name={fields.SUPPLIER}
            label="Supplier"
            required
            allowAdd
            onAddSuccess={(supplier) => h.handleAddNewSupplierSuccess(supplier)}
            onChange={h.handleSupplierChange}
            isParent={false}
            isRetired={false}
            disabled={disabled}
          />
          <SupplierTypeSelector
            name={fields.SUPPLIER_TYPE}
            label="Supplier Type"
            required
            disabled={disabled}
            {...(fv[fields.SUPPLIER_TYPE] === SupplierType.ANCILLARIES && {
              exclude: [],
            })}
          />
        </Stack>
        <TextField
          name={fields.CONFIRMATION_NUMBER}
          label="Confirmation Number"
          required={!!fv.isConfirmed}
          fullWidth
          disabled={disabled}
        />
        <Stack direction="row" spacing={2}>
          <DatePicker
            name={fields.CHECK_IN}
            label="Check In"
            required
            fullWidth
            disabled={disabled}
          />
          <DatePicker
            name={fields.CHECK_OUT}
            label="Check Out"
            required
            fullWidth
            onChange={h.handleCheckoutChange}
            disabled={disabled}
            {...(fv.checkIn && { referenceDate: fv.checkIn })}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <CurrencySelector
              name={fields.CURRENCY}
              label="Currency"
              onChange={(value) => {
                h.handleCurrencyChange(value, (err) =>
                  toast(err, { variant: 'error' }),
                );
              }}
              disabled={disabled || hasPaidPayments}
            />
          </FormControl>

          <ConversionRateField
            name={fields.EXCHANGE_RATE}
            label="Conversion Rate"
            disabled={disabled || fv.currency === userInfo?.homeCurrency?.code}
            isLocked={Boolean(fv.exchangeRateLockedAt)}
            currencyCode={fv.currency}
            onLock={() =>
              setFieldValue(fields.EXCHANGE_RATE_LOCKED_AT, new Date())
            }
            onUnlock={() => setFieldValue(fields.EXCHANGE_RATE_LOCKED_AT, null)}
            onChange={(newRate) => {
              h.handleExchangeRateChange(newRate);
            }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <MoneyTextField
            name={fields.TOTAL}
            label="Total Fare"
            required
            fullWidth
            symbol={currentCurrency?.symbol}
            currencyCode={userInfo?.homeCurrency?.code}
            homeCurrencyConversion={convertToHomeCurrency(fv.total)}
            onChange={h.handleTotalChange}
            disabled={disabled}
          />
          <PaymentTypeSelector
            name={fields.PAYING_ENTITY}
            label="Payment Type"
            disabled={disabled || (!fv.isArc && mode === 'edit')}
            required
            onChange={(value) =>
              h.handlePayingEntityChange(value as PayingEntity)
            }
          />
        </Stack>
        {userInfo?.organization.useManagedTaxes &&
          fv.payingEntity === PayingEntity.CLIENT && (
            <MoneyTextField
              name={fields.CLIENT_PAID_TAXES}
              label="Client Paid GST/HST"
              fullWidth
              symbol={currentCurrency?.symbol}
              currencyCode={userInfo?.homeCurrency?.code}
              homeCurrencyConversion={convertToHomeCurrency(fv.clientPaidTaxes)}
              disabled={disabled}
            />
          )}
        <Switch
          name={fields.IS_COMMISSIONABLE}
          label="Commissionable"
          onClick={() => {
            h.handleCommissionableChange(!fv.isCommissionable);
          }}
          disabled={disabled}
        />
        {fv.isCommissionable && (
          <>
            <Stack direction="row" spacing={2}>
              <MoneyTextField
                name={fields.COMMISSIONABLE_VALUE}
                label="Base Fare"
                value={fv.commissionableValue}
                required
                fullWidth
                onChange={(value) => {
                  h.handleCommissionableValueChange(value);
                }}
                symbol={currentCurrency?.symbol}
                currencyCode={userInfo?.homeCurrency?.code}
                homeCurrencyConversion={convertToHomeCurrency(
                  fv.commissionableValue,
                )}
                disabled={disabled}
              />
              <MoneyTextField
                name={fields.TAXES_AND_FEES}
                label="Taxes & Fees"
                required
                fullWidth
                symbol={currentCurrency?.symbol}
                currencyCode={userInfo?.homeCurrency?.code}
                homeCurrencyConversion={convertToHomeCurrency(fv.taxesAndFees)}
                onChange={h.handleTaxesAndFeesChange}
                disabled={disabled}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <MoneyTextField
                name={fields.COMMISSION}
                label="Est. Commission"
                required
                fullWidth
                onChange={(value) => {
                  h.handleCommissionChange(value);
                }}
                symbol={currentCurrency?.symbol}
                currencyCode={userInfo?.homeCurrency?.code}
                homeCurrencyConversion={convertToHomeCurrency(fv.commission)}
                disabled={disabled}
              />
              <PercentTextField
                name={fields.COMMISSION_PCT}
                label="Commission %"
                labelWhenShrunk="Commission"
                required
                fullWidth
                onChange={(value) => {
                  h.handleCommissionPctChange(value);
                }}
                disabled={disabled}
              />
            </Stack>
          </>
        )}
        <Switch
          label="Markup"
          name={fields.USE_MARKUP}
          disabled={disabled || fv.payingEntity === PayingEntity.CLIENT}
        />
        {fv.useMarkup && (
          <>
            <Stack direction="row" spacing={2}>
              <MoneyTextField
                name={fields.MARKUP}
                label="Markup Amount"
                symbol={currentCurrency?.symbol}
                currencyCode={userInfo?.homeCurrency?.code}
                homeCurrencyConversion={convertToHomeCurrency(
                  fv[fields.MARKUP],
                )}
                required
                onChange={h.handleMarkupAmountChange}
                disabled={disabled}
              />
              <PercentTextField
                name={fields.MARKUP_PCT}
                label="Markup Rate"
                required
                onChange={h.handleMarkupPctChange}
                disabled={disabled}
              />
            </Stack>
            <MoneyTextField
              name="totalWithMarkup"
              label="Total With Markup"
              fullWidth
              required
              symbol={currentCurrency?.symbol}
              currencyCode={userInfo?.homeCurrency?.code}
              homeCurrencyConversion={convertToHomeCurrency(fv.totalWithMarkup)}
              onChange={h.handleTotalWithMarkupChange}
              disabled={disabled}
            />
          </>
        )}
        <Switch
          label="Track payments to supplier"
          name={fields.TRACK_PAYMENTS}
          disabled={
            disabled ||
            fv.payingEntity === PayingEntity.AGENCY ||
            payments.length > 0
          }
        />
        {fv.trackPayments && (
          <BookingFormSupplierPayments
            payments={payments}
            setPayments={setPayments}
            bookingFormValues={fv}
            bookingVoidedDate={bookingVoidedDate}
            disabled={paymentsDisabled}
            required={fv.trackPayments}
          />
        )}
        <Divider />
      </Stack>
      <Stack spacing={2}>
        <TextField name={fields.SUBJECT} label="Subject (optional)" multiline />
        <TextField name={fields.NOTES} label="Advisor Remarks" multiline />
        <TextField
          name={fields.INVOICE_REMARKS}
          label="Invoice Remarks"
          multiline
        />
      </Stack>
    </>
  );
}
