import { Alert, AlertTitle, Box } from '@mui/material';

const PaymentAlert = ({ showWarning }: { showWarning: boolean }) => (
  <Box pb={2}>
    {showWarning ? (
      <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle>
        Changes will impact accounting
      </Alert>
    ) : (
      <Alert severity="error">
        <AlertTitle>Payment Locked</AlertTitle>
        Editable by admin only
      </Alert>
    )}
  </Box>
);

export default PaymentAlert;
