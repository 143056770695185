import { LinearProgress, type LinearProgressProps, Stack } from '@mui/material';
import T from 'components/common/T';
import React from 'react';

const BookingCommissionSplitsLinearProgress = ({
  totalAssignedPct,
  maxPercent,
  ...props
}: Omit<LinearProgressProps, 'color' | 'variant'> & {
  totalAssignedPct: number;
  maxPercent: number;
}) => {
  const color = totalAssignedPct === maxPercent ? 'success' : 'error';
  const linearProgressValue =
    (totalAssignedPct / maxPercent) * 100 > 100
      ? 100
      : (totalAssignedPct / maxPercent) * 100;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <LinearProgress
        variant="determinate"
        color={color}
        value={linearProgressValue}
        {...props}
        sx={{ flex: 1 }}
      />
      <T
        variant="body2"
        color="text.secondary"
      >{`Total ${totalAssignedPct}% / ${maxPercent}%`}</T>
    </Stack>
  );
};

export default BookingCommissionSplitsLinearProgress;
