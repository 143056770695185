import type { SabrePnrDto } from 'dtos';
import useSWR from 'swr';
import { fetcher } from 'utils/client/swr/jsonFetcher';

export type UseBookingSabreDetailsProps = {
  bookingId: string;
};

export default function useBookingSabreDetails({
  bookingId,
}: UseBookingSabreDetailsProps) {
  const key = `bookings/${bookingId}/sabre-pnr`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<SabrePnrDto, Error>(key, fetcher);

  return {
    sabreDetails: data?.responseText ?? 'Could not fetch Sabre details',
    isLoading,
    error,
    refresh: mutate,
  };
}
