import { createPaginatedResponseDto, isoDateRegex } from 'dtos';
import { z } from 'zod';

export interface IChangeLogService {
  getChangeLog(): Promise<ChangeLogEventDto[]>;
}

export enum ChangeLogEntityType {
  BOOKING = 'BOOKING',
  BOOKING_REFUND = 'BOOKING_REFUND',
}

export type ChangeLogConfig = {
  /** Primary table for the entity */
  entityTable: {
    /** Name of the primary table */
    name: string;

    /** Name of the column in the primary table that contains the ID of the user who created the entity */
    createdByColumnName: string;

    /** Name of the column in the primary table that contains the ID of the user who deleted the entity */
    deletedByColumnName: string;
  };

  /** History table for the entity, if it exists */
  historyTable?: {
    /** Name of the history table */
    name: string;

    /** Name of the foreign key from the history table to the primary table */
    foreignKey: string;

    /** Name of the column in the history table that contains the ID of the user who created the entity */
    createdByColumnName: string;
  };

  /** Key: a column to include in the change log - must have the same name in the history table as in the entity table
   * Value: a human readable name for the column */
  columns: Record<
    string,
    {
      type: FieldType;
      label: string;
    }
  >;
};

export type ChangeLogUpdate = {
  changedAt: Date;
  changedById: string;
  changeNumber: number;
  field: string;
  oldValue: string | null;
  newValue: string | null;
};

export enum ChangeEventType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  MISC = 'MISC',
}

export type ChangeEvent = {
  changedAt: Date;
  changedById: string | null;
};

export type CreateEvent = ChangeEvent & {
  type: ChangeEventType.CREATE;
};

export type UpdateEvent = ChangeEvent & {
  type: ChangeEventType.UPDATE;
  field: {
    type: FieldType;
    name: string;
    label: string;
    oldValue: string | null;
    newValue: string | null;
  };
};

export type DeleteEvent = ChangeEvent & {
  type: ChangeEventType.DELETE;
};

export type MiscEvent = ChangeEvent & {
  type: ChangeEventType.MISC;
  label: string;
};

export type ChangeLogEvent =
  | CreateEvent
  | UpdateEvent
  | DeleteEvent
  | MiscEvent;

export const entityNameMap: Record<ChangeLogEntityType, string> = {
  [ChangeLogEntityType.BOOKING]: 'Booking',
  [ChangeLogEntityType.BOOKING_REFUND]: 'Refund',
};

const ChangeEventDto = z.object({
  entityName: z.string(),
  changedAt: z.string().regex(isoDateRegex),
  changedBy: z
    .object({
      color: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      profileImageUrl: z.string().nullable(),
    })
    .optional(),
});

const CreateEventDto = ChangeEventDto.extend({
  type: z.literal(ChangeEventType.CREATE),
});

const DeleteEventDto = ChangeEventDto.extend({
  type: z.literal(ChangeEventType.DELETE),
});

export enum FieldType {
  STRING = 'STRING',
  MONEY = 'MONEY',
  DATE = 'DATE',
  PERCENT = 'PERCENT',
  NUMBER = 'NUMBER',
}

const UpdateFieldDto = z.object({
  name: z.string(),
  label: z.string(),
  oldValue: z.string().nullable(),
  newValue: z.string().nullable(),
});

const StringUpdateFieldDto = UpdateFieldDto.extend({
  type: z.literal(FieldType.STRING),
});

const MoneyUpdateFieldDto = UpdateFieldDto.extend({
  type: z.literal(FieldType.MONEY),
  beforeCurrency: z.string().nullable(),
  afterCurrency: z.string(),
});

const DateUpdateFieldDto = UpdateFieldDto.extend({
  type: z.literal(FieldType.DATE),
});

const PercentUpdateFieldDto = UpdateFieldDto.extend({
  type: z.literal(FieldType.PERCENT),
});

const NumberUpdateFieldDto = UpdateFieldDto.extend({
  type: z.literal(FieldType.NUMBER),
});

export const UpdateEventDto = ChangeEventDto.extend({
  type: z.literal(ChangeEventType.UPDATE),
  field: z.discriminatedUnion('type', [
    StringUpdateFieldDto,
    MoneyUpdateFieldDto,
    DateUpdateFieldDto,
    PercentUpdateFieldDto,
    NumberUpdateFieldDto,
  ]),
});
export type UpdateEventDto = z.infer<typeof UpdateEventDto>;

const MiscEventDto = ChangeEventDto.extend({
  type: z.literal(ChangeEventType.MISC),
  label: z.string(),
});

export const ChangeLogEventDto = z.discriminatedUnion('type', [
  CreateEventDto,
  UpdateEventDto,
  DeleteEventDto,
  MiscEventDto,
]);
export type ChangeLogEventDto = z.infer<typeof ChangeLogEventDto>;

export const PaginatedChangeLogEventDto =
  createPaginatedResponseDto(ChangeLogEventDto);
export type PaginatedChangeLogEventDto = z.infer<
  typeof PaginatedChangeLogEventDto
>;
