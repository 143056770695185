import Timeline from '@mui/lab/Timeline';
import { timelineConnectorClasses } from '@mui/lab/TimelineConnector';
import { timelineDotClasses } from '@mui/lab/TimelineDot';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { CircularProgress, Stack, typographyClasses } from '@mui/material';
import { avatarClasses } from '@mui/material/Avatar';
import useChangeLog from 'hooks/useChangeLog';
import { useState } from 'react';
import {
  ChangeEventType,
  type ChangeLogEntityType,
} from 'services/changeLog/types';
import { ChangeLogTimelineItem } from './ChangeLogTimelineItem';
import { Pagination } from './Pagination';

type ChangeLogTimelineProps = {
  entityId: string;
  type: ChangeLogEntityType;
};

export const ChangeLogTimeline = ({
  entityId,
  type,
}: ChangeLogTimelineProps) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { data, meta, isLoading } = useChangeLog({
    entityId,
    type,
    page,
    pageSize,
  });

  if (!isLoading && (!data || !meta)) {
    return null;
  }

  return (
    <Stack gap={2} height="600px">
      {isLoading ? (
        <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
      ) : (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            [`& .${timelineItemClasses.root}:last-child`]: {
              [`& .${timelineConnectorClasses.root}`]: {
                display: 'none',
              },
              [`& .${avatarClasses.root}`]: {
                mb: 0,
              },
              [`& .${timelineDotClasses.root}`]: {
                mb: 0,
              },
            },
            [`& .${avatarClasses.root}`]: {
              [`& .${typographyClasses.root}`]: {
                fontSize: '0.75rem',
              },
            },
            p: 0,
            m: 0,
            overflow: 'scroll',
          }}
        >
          {data.map((event, index) => (
            <ChangeLogTimelineItem
              key={`${index}-${event.type}-${event.changedAt}${
                event.type === ChangeEventType.UPDATE
                  ? `-${event.field.name}`
                  : ''
              }`}
              event={event}
            />
          ))}
        </Timeline>
      )}
      {meta && (
        <Pagination
          count={meta.totalRowCount}
          page={page}
          onChangePage={setPage}
          pageSize={pageSize}
          onChangePageSize={(size) => {
            setPageSize(size);
            setPage(0);
          }}
          pageSizeLabel="Events per page: "
        />
      )}
    </Stack>
  );
};
