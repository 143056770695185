import type {
  ChangeLogEntityType,
  ChangeLogEventDto,
} from 'services/changeLog/types';
import useSWR from 'swr';
import type { PaginatedData } from 'types/paginatedData';
import { getUrlSearchParams } from 'utils/client/fetch';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useChangeLog({
  entityId,
  type,
  page = 0,
  pageSize = 10,
}: {
  entityId: string | undefined;
  type: ChangeLogEntityType;
  page: number;
  pageSize: number;
}) {
  const params = getUrlSearchParams({
    type,
    page,
    pageSize,
  });
  const endpoint = `history/${entityId}?${params}`;

  const {
    data: response,
    isLoading,
    isValidating,
    error,
    mutate,
  } = useSWR<PaginatedData<ChangeLogEventDto>, Error>(
    endpoint,
    entityId ? fetcher : null,
  );

  const { data, meta } = (response as PaginatedData<ChangeLogEventDto>) || {};

  return {
    data,
    meta,
    error,
    refresh: mutate,
    isLoading: isLoading || isValidating,
  };
}
